import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { findMetaMaskAccounts } from './ConnectMetaMask';

export default function Wallets() {
    const [inputValue, setInputValue] = useState('');
    const [metaWallets, setMetaWallets] = useState([]);

    const walletReducer = useSelector((state) => state.walletReducer );
    const { repository } = walletReducer;

    const dispatch = useDispatch();

    if (repository == null) {
        return (<div></div>)
    }

    const hasMetaWallets = window.ethereum !== null;
    
    // Sort List of Wallets by date
    var sortWallets = repository.walletTypeMap["REMOTE"] || new Set([]);
    var sortedWallets = [...sortWallets].map(function(key) {
        return repository.wallets[key];
    });

    sortedWallets.sort(function(first, second) {
      return second.insertedDate - first.insertedDate;
    })

    const metaMaskIds = [...(repository.walletTypeMap["LOCAL:METAMASK"] || [])];
    const metaMaskTokens = new Set(metaMaskIds.map(walletId => {
        return repository.wallets[walletId].blockChainToken;
    }));

    async function createWallet() {
        if (!inputValue) return;
        const wallet = {
            block_token: inputValue,
            source: "REMOTE"
        };
        dispatch(repository.insertNewWallet(wallet));
    }

    function toggleWallet(item, walletId) {
        if (item.target.checked == true) {
            dispatch(repository.insertNewWallet({
                block_token: walletId,
                source:  "LOCAL:METAMASK"
            }))
        } else {
            const index = metaMaskIds.findIndex((key) =>
                repository.wallets[key] ? repository.wallets[key].blockChainToken == walletId : false);

            if (index >= 0) {
                dispatch(repository.removeWallet(metaMaskIds[index]));
            }
        }
    }

    const connectMetaMask = async () => {
        const wallets =  await findMetaMaskAccounts();
        setMetaWallets(wallets);
    }

    async function deleteWallet(id) {
        dispatch(repository.removeWallet(id));
    }

    async function onChange(e) {
        e.persist();
        setInputValue(e.target.value);
    }

    return (
        <div>
            <div>
                <h2>User Wallets</h2>
                <h3>Remote Wallets</h3>
                <table>
                {
                    sortedWallets.map((wallet) => (
                        <tr key={wallet.id}>
                            <td>
                                <Link to={`/wallet/{wallet.id}/`} key={wallet.id}>
                                    <p>{wallet.blockChainToken}</p>
                                </Link>
                            </td>
                            <td>
                                <button onClick={() => deleteWallet(wallet.id)}>X</button>
                            </td>
                            </tr>
                        ))
                }
                </table>
                <div>
                    <div>
                        <input
                            placeholder="Wallet Token"
                            onChange={onChange}
                        />
                    </div>
                    <div>
                        <button onClick={createWallet}>Create Wallet</button>
                    </div>
                </div>
            </div>
            <div>
                <h4>MetaMask Wallets</h4>
                {
                    (() => {
                        return hasMetaWallets && metaWallets.length > 0 ? (<table>
                            {
                                metaWallets.map((wallet) => <tr key={wallet + "-row"}>
                                    <td>
                                        <Link to={`wallet/{wallet}/`} key={wallet}>{wallet}</Link>
                                    </td>
                                    <td>
                                        <input type="checkbox" checked={metaMaskTokens.has(wallet)} onChange={(element) => {
                                            toggleWallet(element, wallet);
                                        }}></input>
                                    </td>
                                </tr>)
                            }
                        </table>) : (
                            <button onClick={connectMetaMask}>Connect MetaMask</button>
                        );
                    })()
                }
            </div>
        </div>
    )
}