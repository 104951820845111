import React from 'react';

export const DestinationComponents = {
    __DISPLAY_PLACEHOLDER__: function(attributes, asDestination, index) {
        const style = attributes ? attributes.style : null;
        const width = attributes && attributes.size ? attributes.size.width : null;
        const height = attributes && attributes.size ? attributes.size.height : null;

        return (<div key={asDestination + "-" + index}
            style={{...style, backgroundColor: "pink"}}
            width={width}
            height={height} ></div>);
    },

    DISPLAY_IMAGE: function(attributes, asDestination, index) {
        const style = attributes ? attributes.style : null;
        const width = attributes && attributes.size ? attributes.size.width : null;
        const height = attributes && attributes.size ? attributes.size.height : null;

        return (<img src={this.url}
            key={asDestination + "-" + index}
            style={style}
            width={width}
            height={height} ></img>);
    }
}