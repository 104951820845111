import React from 'react';

import AttachableDisplay from '../AttachableDisplay';

export const SimpleDisplayComponentFunctions = {
    RenderImageWithStyle: function(entry, style) {
        const attachment = entry.attachment;
        const attributes = entry.attributes || { };
        const asDestination = entry.asDestination;

        if (attributes.style == null) {
            attributes.style = { };
        }

        attributes.style = {
            ...attributes.style,
            ...style
        }

        return (attachment != null ? attachment.destinationComponent(attributes, asDestination) : null);
    },

    RenderChildInstance: function(entry, params, index) {
        const attachment = entry.attachment;
        const attributes = entry.attributes || { };
        const asDestination = entry.asDestination;

        const renderParams = {
            ...params,
            childIndex: index,
            attributes: attributes
        }

        return (attachment != null ? attachment.destinationComponent(renderParams, asDestination) : null);
    }
}

export class BareDisplay extends AttachableDisplay {
    attachmentSlots = {
        "default": {
            capacity: 1,
            accepts: ["DISPLAY_IMAGE"]
        }
    }

    instanceDestinations = {
        "MODEL_IMAGE": [
            function(attributes, asDestination) {
                return (this.renderInstance(attributes))
            }
        ]
    }

    instanceBoundsForAttributes(displayInstance, attributes) {
        return {
            width: 12,
            height: 12,
            unit: "inch"
        }
    }

    handleInstanceSlotName(renderParams, slotName, slotAttachments) {
        switch (slotName) {
            case "default":
                const imgStyle = {
                    objectFit: "contain",
                    maxWidth: "100%",
                    maxHeight: "100%",
                    width: "auto",
                    height: "auto",
                    verticalAlign: "middle",
                    transform: "translate(-50%, -50%)",
                    position: "relative",
                    left: "50%",
                    top: "50%",
                }
                return slotAttachments.map((slotEntry, entryIndex) =>
                    SimpleDisplayComponentFunctions.RenderImageWithStyle(slotEntry, imgStyle, entryIndex));
            default:
                return null;
        }
    }

    handleInstanceRoot(rootAttributes, processComponents) {
        const imageContainerStyle = {
            position: "relative",
            display: "flex",
            overflow: "hidden",
            maxWidth: rootAttributes.renderSize ? (rootAttributes.renderSize.width + "px") : null,
            maxHeight: rootAttributes.renderSize ? (rootAttributes.renderSize.height + "px") : null,
            width: rootAttributes.renderSize ? (rootAttributes.renderSize.width + "px") : null,
            height: rootAttributes.renderSize ? (rootAttributes.renderSize.height + "px") : null
        }
        return (
            <div key={this.instanceId} style={imageContainerStyle}>
            {
                (processComponents(rootAttributes))
            }
            </div>
        )
    }

    serializableInfo() {
        return {
            displayType: this.internalId,
            variant: "default"
        }
    }
}

export class SimpleFrameDisplay extends AttachableDisplay {
    defaults = {
        frameSize: 10,
        frameColor: 'black',
        insetMinSize: 7,
        insetColor: 'white',
        ratio: {
            width: 4,
            height: 6
        }
    }

    attachmentSlots = {
        "picture": {
            capacity: 1,
            accepts: ["DISPLAY_IMAGE"]
        }
    }

    instanceDestinations = {
        "MODEL_IMAGE": [
            function(attributes, asDestination) {
                return (this.renderInstance(attributes))
            }
        ]
    }

    instanceBoundsForAttributes(displayInstance, attributes) {
        return {
            width: 8,
            height: 12,
            unit: "inch"
        }
    }

    handleInstanceSlotName(renderParams, slotName, slotAttachments) {
        switch (slotName) {
            case "picture":
                const imgStyle = {
                    objectFit: "contain",
                    maxWidth: "100%",
                    maxHeight: "100%",
                    width: "auto",
                    height: "auto",
                    verticalAlign: "middle"
                }
                return slotAttachments.map((slotEntry, entryIndex) =>
                    SimpleDisplayComponentFunctions.RenderImageWithStyle(slotEntry, imgStyle, entryIndex));
            default:
                return null;
        }
    }

    handleInstanceRoot(rootAttributes, processComponents) {
        var renderAttributes = {
            ...this.defaults
        }
        if (rootAttributes.attributes != null) {
            renderAttributes = {
                ...renderAttributes,
                ...rootAttributes.attributes
            };
        }

        const orientation = renderAttributes.ratio.width > renderAttributes.ratio.height ? "landscape" : "portrait";

        var size = { width: 0, height: 0 };
        var borderSize;
        var insetSize;
        if (rootAttributes.renderSize) {
            if (orientation == "landscape") {
                size.width = rootAttributes.renderSize.width || 1;
                size.height = (size.width / renderAttributes.ratio.width) * renderAttributes.ratio.height;
                borderSize = (size.width * renderAttributes.frameSize) / 100;
                insetSize = (size.width * renderAttributes.insetMinSize) / 100;
            } else {
                size.height = rootAttributes.renderSize.height || 1;
                size.width = (size.height / renderAttributes.ratio.height) * renderAttributes.ratio.width;
                borderSize = (size.height * renderAttributes.frameSize) / 100;
                insetSize = (size.height * renderAttributes.insetMinSize) / 100;
            }
        }

        const containerStyle = {
            position: "absolute"
        }
        const style = {
            overflow: "hidden",
            position: "relative",
            width: size.width - (2 * borderSize),
            height: size.height - (2 * borderSize),
            border: borderSize + "px solid " + renderAttributes.frameColor,
            color: renderAttributes.insetColor,
            [orientation == "landscape" ? "top" : "left"]: orientation == "landscape"
                ? (rootAttributes.renderSize.height - size.height) / 2
                : (rootAttributes.renderSize.width - size.width) / 2
        }
        const imageContainerStyle = {
            position: "relative",
            display: "flex",
            maxWidth: size.width - (2 * borderSize) - (2 * insetSize),
            maxHeight: size.height - (2 * borderSize) - (2 * insetSize),
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)"
        }
        return (
            <div key={this.instanceId} style={containerStyle}>
                <div style={style}>
                    <div style={imageContainerStyle}>
                    {
                        (processComponents(rootAttributes))
                    }
                    </div>
                </div>
            </div>
        )
    }

    serializableInfo() {
        return {
            displayType: this.internalId,
            variant: "default"
        }
    }
}