import React, { useState, useEffect, useRef } from 'react';
import { API } from 'aws-amplify';
import { WalletWithResolvedTokensQuery as ResolverQuery } from './WalletsModel';

function TokenImage(token) {
    if (token.token.resolved_token_meta != null &&
        token.token.resolved_token_meta[0] != null &&
        token.token.resolved_token_meta[0]["attributes"] != null &&
        token.token.resolved_token_meta[0]["attributes"][0] != null &&
        token.token.resolved_token_meta[0]["attributes"][0]["value"] != null
    ) {
        return (
            <img src={JSON.parse(token.token.resolved_token_meta[0].attributes[0].value).slice(1, -1)} width="200" height="240"></img>
            )
    } else {
        return (
        <p>{token.token.resolved_token_meta}</p>
        )
    }
}

export default function WalletViewer() {
    const [inputValue, setInputValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [endOfStream, setIsEndOfStream] = useState(true);
    const [uniqueKeys, setUniqueKeys] = useState(new Set());
    const [walletTokens, setWalletTokens] = useState({ tokens: [ ], page: 0 });

    const loader = useRef(null);
    const handleObserver = (entities) => {
        const target = entities[0];
        if (target.isIntersecting && !endOfStream) {
            !loading && resolveWallet(true);
        }
    }

    useEffect(() => {
        var options = {
             root: null,
             rootMargin: "20px",
             threshold: 1.0
        };

        const observer = new IntersectionObserver(handleObserver, options);
        if (loader && loader.current) {
            observer.observe(loader.current)
        }
        
        return () => {
            if (loader.current) {
                observer.unobserve(loader.current);
            }
        }
    }, [loader, handleObserver]);

    async function resolveWallet(next) {
        if (!inputValue) return;

        if (next == null || next == false) {
            setIsEndOfStream(false);
            setWalletTokens({ tokens: [], page: 0 });
        } else if (endOfStream) {
            return;
        }

        setLoading(true);

        console.log("loading for " + (walletTokens.page + 1));

        await API.graphql({
            query: ResolverQuery,
            variables: { wallet_block_token: inputValue, operation: "", page: walletTokens.page + 1, limit: 10 }
        }).then(walletData => {
            if (walletData.data.resolveWallets.error != null) {
                return;
            }
            if (walletData.data.resolveWallets.items == null ||
                walletData.data.resolveWallets.items.length == 0) {
                return;
            }

            walletData.data.resolveWallets.items.forEach(wallet => {
                var tokens = wallet.tokens;
                if (tokens == null) {
                    tokens = [];
                }

                var currentUniqueKeys = uniqueKeys;
                var filteredTokens = [];
                tokens.forEach(token => {
                    if (token.block_token != null &&
                        !currentUniqueKeys.has(token.block_token)) {
                            filteredTokens.push(token);
                            uniqueKeys.add(token.block_token);
                    }
                })

                setUniqueKeys(currentUniqueKeys);

                var page = wallet.page;
                if (filteredTokens.length == 0 && page == null) {
                    setIsEndOfStream(true);
                    console.log("Ending stream.");
                } else {
                    if (page == null) {
                        page = 0;
                    }
                    setWalletTokens({ tokens: walletTokens.tokens.concat(filteredTokens), page: page });
                    console.log("Loaded page " + page);
                }
            })

            setLoading(false);
        })
    }

    async function defaultResolve() {
        resolveWallet(false);
    }

    async function onChange(e) {
        e.persist();
        setInputValue(e.target.value);
    }

    return (
            <div>
                <div>
                    <h2>View Token Resolved Wallet</h2>
                </div>
                <div>
                    <input
                        placeholder="Wallet Token"
                        onChange={onChange}
                    />
                </div>
                <div>
                    <button onClick={defaultResolve}>View Wallet</button>
                </div>
                <div>
                {
                    walletTokens.tokens.map((token) => (
                        <div key={token.block_token}>
                            <p>
                                {token.block_token}
                            </p>
                            <TokenImage token={token} />
                        </div>
                      ))
                }
                </div>
                <div ref={loader}>
                    { endOfStream ? null : <h2>Loading...</h2> }
                </div>
            </div>
    )
}