import DisplayInstance from "../Display/DisplayInstance";
import { DisplayLocation, Showcase, ShowcaseLocation } from "../Showcase/Showcase";
import { WalletToken } from "../WalletsModel";
import { ADD_RESOLUTION, ResolutionPlaceHolder } from "./ConnectionResolution";

export const defs = function() {
    for (let value of arguments) {
        if (value !== null && typeof value !== "undefined") {
            return value;
        }
    }

    return null;
}

export const unitConverter = (values, attributes) => { // For percentage, we need a reference that isn't in percentage units
    const mapToPix = {
        "inch": 16.0,
        "pixel": 1.0
    }

    var toPix = { };
    var unit = values.unit || attributes.from || "pixel";
    var scale = defs(attributes.scale, { })
    if (mapToPix.hasOwnProperty(unit)) {
        Object.keys(values).map ((key) => {
            if (key != "unit") {
                toPix[key] = values[key] * mapToPix[unit] * defs(scale[key], 1.0);
            }
        })
    }

    if (attributes.to != null) {
        var toUnit = { }
        Object.keys(toPix).map ((key) => {
            toUnit[key] = toPix[key] * mapToPix[attributes.to];
        })
        toUnit.unit = attributes.to;
        return toUnit;
    } else {
        toPix.unit = "pixel";
        return toPix;
    }
}

export const checkContainment = (boxes) => (frame, findAll = false) => {
    var result = new Array();
    const lengthOffset = 0.0000001
    const frameWidth = frame.width * defs( defs(frame.scale, { x: 1.0 }).x, 1.0 );
    const frameHeight = frame.height * defs( defs(frame.scale, { y: 1.0 }).y, 1.0 );

    for (let box of boxes) {
        const left = defs(box.x, box.left)
        const top = defs(box.y, box.top)

        const right = defs(box.right, left + defs(box.width, 1) );
        const bottom = defs(box.bottom, top + defs(box.height, 1));

        const leftMargin = frame.x > left && frame.x < right;
        const topMargin = frame.y > top && frame.y < bottom;
        const rightMargin = (frame.x + frameWidth) > left && (frame.x + frameWidth) < (right + lengthOffset);
        const bottomMargin = (frame.y + frameHeight) > top && (frame.y + frameHeight) < (bottom + lengthOffset);

        if ((leftMargin || rightMargin) && (topMargin || bottomMargin)) {
            result.push(box);
        } else {
            const leftMargin = left > frame.x && right < frame.x;
            const topMargin = top > frame.y && bottom < frame.y;
            const rightMargin = right > frame.x && right < (frame.x + frameWidth + lengthOffset);
            const bottomMargin = bottom > frame.y && bottom < (frame.y + frameHeight + lengthOffset);

            if ((leftMargin || rightMargin) && (topMargin || bottomMargin))
                result.push(box);
        }

        if (findAll == false && result.length > 0)
            break;
    }

    return result;
}

export const serializedType = (object) => {
    if (object == null) {
        return null
    }  
    
    if (object instanceof WalletToken) {
        return "WALLET_TOKEN"
    }
    
    if (object instanceof DisplayInstance) {
        return "DISPLAY_INSTANCE"
    }
    
    if (object instanceof DisplayLocation) {
        return "DISPLAY_LOCATION"
    }
    
    if (object instanceof Showcase) {
        return "SHOWCASE"
    }

    return "UNKNOWN"
}

export const serializedTypeObject = (object) => {
    const instanceType = serializedType(object);

    if (instanceType == null) {
        return null;
    }

    var result =  {
        type: instanceType
    }

    switch (instanceType) {
        case "SHOWCASE":
        case "DISPLAY_LOCATION":
        case "WALLET_TOKEN":
            result["id"] = object.id;
            break;
        case "DISPLAY_INSTANCE":
            result["id"] = object.instanceId;
            break;
        default:
            result["id"] = "n/a";
    }

    return result;
}

export const resolveSerializedObject = (object, dispatch, state, onResolve) => {
    var resolved = null;

    switch (object.type) {
        case "SHOWCASE":

            break;
        case "DISPLAY_LOCATION":
            break;
        case "WALLET_TOKEN":
            const walletKeys = Object.keys(state().walletReducer.repository.wallets);
            const walletIndex = walletKeys.findIndex(key => {
                state().walletReducer.repository.wallets[key].tokens.hasOwnProperty(object.id)
            })

            if (walletIndex >= 0 &&
                state().walletReducer.repository.wallets[walletKeys[walletIndex]].tokens.hasOwnProperty(object.id)) {
                resolved = state().walletReducer[object.id];
            }
            break;
        case "DISPLAY_INSTANCE":
            if (state().displayReducer.displayInstances.hasOwnProperty(object.id)) {
                resolved = state().displayReducer.displayInstances[object.id];
            }
            break;
        default:
            return null;
    }

    if (resolved == null) {
        var placeholder = new ResolutionPlaceHolder();
        placeholder.reference = object;
        placeholder.onResolve = onResolve;

        dispatch({
            type: ADD_RESOLUTION,
            payload: placeholder
        })

        return [false, placeholder];
    } else {
        onResolve(resolved)
        return [true, resolved];
    }
}