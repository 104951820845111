import React, { useEffect, useState, useContext, useMemo, useRef } from 'react';

import { useDrop } from 'react-dnd';

import CreatorContext from './CreatorContext';
import { PanelContainerStyle, PanelScrollAreaStyle, ItemSelected } from './Styles';

const marginTabSize = 18;

const SelectedLocationStyle = {
    outline: "solid",
    backgroundColor: "azure",
    borderRadius: "5px"
}

const DetachStyle = {
    borderRadius: "9px",
    borderColor: "black",
    borderWidth: "2px",
    width: "11px",
    height: "11px",
    float: "right",
    borderStyle: "solid",
    lineHeight: "10px",
    cursor: "pointer",
    padding: "0px 0px 3px 3px",
    marginRight: "4px"
}

export function LocationItemView({ itemTitle, margin, style, type, locationId, slotName, refItem }) {
    const creatorContext = useContext(CreatorContext);

    var [hover, setHover] = useState(false);
    var [instances, setInstances] = useState(0);

    if (Object.keys(creatorContext.displays.displayInstances).length != instances) {
        setInstances(Object.keys(creatorContext.displays.displayInstances).length);
    }

    const updateHover = (item, monitor) => {
        if (!monitor.canDrop() || !monitor.isOver({shallow: true})) {
            return;
        }

        setHover(true);
    }

    const canAccept = (item) => {
        const displayInstance = creatorContext.displays.displayInstances[item.instanceId];
        if (displayInstance == null) {
            return false;
        }
        switch (type) {
            case "location-slot":
                return displayInstance.displayParent == null &&
                    creatorContext.showcase.current.canAccept(displayInstance, locationId, slotName, null)[0];
            default: return false;
        }
    }

    const dropDisplayOnItem = (item) => {
        const displayInstance = creatorContext.displays.displayInstances[item.instanceId];
        if (displayInstance == null) {
            return false;
        }
        switch (type) {
            case "location-slot":
                creatorContext.showcase.attachDisplay(displayInstance, null, locationId, slotName, null);
                break;
            default:
                break;
        }
    }

    const useLeave = (callback, isOver, didDrop) => {
        const ref = useRef(isOver)

        useEffect(() => {
            if (ref.current && !isOver && !didDrop) {
                callback()
            }

            ref.current = isOver
        }, [hover, instances, isOver, didDrop])
    }

    const [{ isOver, canDrop, didDrop }, drop] = useDrop(
        () => ({
            accept: "display",
            drop: dropDisplayOnItem,
            hover: updateHover,
            canDrop: canAccept,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop()
            })
        }), [hover, instances]
    )

    useLeave(() => setHover(false), isOver, didDrop);

    const detachItem = (control) => {
        creatorContext.showcase.detachDisplay(refItem);
    }

    var itemStyle = {
        ...style || { },
        paddingLeft: margin,
    }

    if (hover) {
        itemStyle = {
            ...itemStyle,
            ...ItemSelected
        }
    }

    if (type == "location-display") {
        itemStyle = {
            ...itemStyle,
            display: "inline"
        }
    }

    return (
        <div key={locationId+slotName} ref={canDrop ? drop : null}>
            <p style={itemStyle}>{itemTitle}</p>
            {
                type == "location-display" ? (<div style={DetachStyle} onClick={detachItem}>x</div>) : null
            }
        </div>
    )
}

export function LocationSidebar() {
    const creatorContext = useContext(CreatorContext);

    const [displayLocations, displayInstanceMap] = useMemo(() =>
        [creatorContext.showcase.current.locationAdapter.displayLocations,
         creatorContext.showcase.current.locationAdapter.displayInstanceMap],
        [creatorContext.showcase.interaction]);

    const selectLocation = (location) => {
        creatorContext.showcase.selectFocus({location: location});
    }

    const locationStyle = {
        padding: "4px"
    }
    const selectedStyle = {
        ...SelectedLocationStyle,
        ...locationStyle
    }

    const selectedDisplayStyle = {
        outlineWidth: "2px",
        outlineStyle: "dotted",
        outlineColor: "red"
    }

    return (
        <div style={PanelContainerStyle} key="nuseums.display.location">
            <h3>Display Locations</h3>
            <div style={PanelScrollAreaStyle}>
            {
                // Return all locations
                displayLocations.map( (location) =>
                {
                    return (
                        <div key={ location.id }
                            style={ location.id == creatorContext.showcase.editorFocus.location ? selectedStyle : locationStyle }
                            onClick={(() => selectLocation(location.id))}>
                            <LocationItemView itemTitle={ location.name }
                             margin={ (marginTabSize * 0) }
                             style={ { } }
                             type="location"
                             locationId={location.id}
                             />
                            <div>
                            {
                                // Return all attachment slots
                                Object.keys(location.displayAttachmentSlots).map((slotName) =>
                                {
                                    return (
                                        <div key={ location.id + slotName }>
                                            <LocationItemView itemTitle={ slotName }
                                             margin={ marginTabSize * 1 }
                                             style={ { } }
                                             type="location-slot"
                                             locationId={location.id}
                                             slotName={slotName}
                                             />
                                            {
                                                (() => {
                                                    // Return all attached items on this slot
                                                    if (displayInstanceMap !== null &&
                                                        displayInstanceMap.hasOwnProperty(location.id) &&
                                                        displayInstanceMap[location.id].hasOwnProperty(slotName)) {
                                                        return (
                                                            displayInstanceMap[location.id][slotName].map((attachment) =>
                                                            {
                                                                return attachment != null ? (
                                                                    <div key={ attachment.display.instanceId }>
                                                                        <LocationItemView itemTitle={ attachment.display.instanceName }
                                                                         margin={ marginTabSize * 2 }
                                                                         refItem={ attachment.display }
                                                                         style={
                                                                            creatorContext.showcase.selectedBounds.findIndex((bounds) =>
                                                                                bounds.id == attachment.display.instanceId) >= 0
                                                                                    ? selectedDisplayStyle
                                                                                    : null
                                                                         }
                                                                         type="location-display"
                                                                         />
                                                                    </div>
                                                                ) : (<div style={{ paddingLeft: (marginTabSize * 2)+"px" }}>null</div>)
                                                            })
                                                        )
                                                    } else {
                                                        return (<p style={{ paddingLeft: marginTabSize * 2 }}>empty</p>)
                                                    }
                                                })()
                                            }
                                        </div>
                                    )
                                })
                            }
                            </div>
                        </div>
                    )
                })
            }
            </div>
        </div>
    )
}