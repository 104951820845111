import { GUEST_USER } from "./WalletsModel"

export const authorizedAccess = (user, func) => {
    return (user == GUEST_USER) ? (<Unauthorized />) : (func);
}

export function Unauthorized() {
    return (
        <div><h2>Access Unauthorized</h2></div>
    )
}