import React, { useContext } from 'react';

import CreatorContext from './CreatorContext';
import { TokenImageStyle, PanelContainerStyle, PanelScrollAreaStyle, PanelImageContainerStyle, ItemSelected } from './Styles';

export function TokenView(pars) {
    const attributes = {
        style: TokenImageStyle
    }
    const component = pars.token.componentForDestinationOrDefault(pars.filteredDestination, attributes);

    return (
        <div key={pars.token.id}
            style={pars.selected(pars.token.id, "token") != -1 ? ItemSelected : null}
            onClick={pars.click}>
            {component}
        </div>
    )
}

export function TokenSidebar() {
    const creatorContext = useContext(CreatorContext);

    // Sort List of  Wallets by date
    var sortWallets = creatorContext.repository.wallets;
    var sortedWallets = Object.keys(sortWallets).map(function(key) {
       return sortWallets[key];
    });

    sortedWallets.sort(function(first, second) {
        return second.insertedDate - first.insertedDate;
    })

    // We will have all the users wallets loaded and all of their tokens loaded
    // We will go through all the items and resolve the wallets in smaller batches.

    // Set a filter display is selected first
    var selectionFilter = null;
    var filterAgainst = null;
    const tokenSelectionIndex = creatorContext.selection.selected.findIndex((item) => item.item == "token");
    if (tokenSelectionIndex - 1 >= 0) { // Selected, but not first selected
        filterAgainst = creatorContext.selection.selected[tokenSelectionIndex - 1];
    } else if (creatorContext.selection.selected.length > 0) {
        filterAgainst = creatorContext.selection.selected[creatorContext.selection.selected.length - 1];
    }

    if (filterAgainst) {
       switch(filterAgainst.item) {
           case "display":
               const display = filterAgainst.object;
               selectionFilter = { display: display };
               break;
           default: break;
       }
    }

    const filter = (token) => {
       if (selectionFilter) {
           if (selectionFilter.display == null || !selectionFilter.display.canAttach(null, token)) {
               return false;
           }
       }
       return true;
    };

    return (
       <div style={PanelContainerStyle}>
           <h3>Wallet tokens</h3>
           <div style={PanelScrollAreaStyle}>
           {
               sortedWallets.map((wallet) => {
                   return (
                       <div key={wallet.id}>
                           <h4>{wallet.blockChainToken} ({Object.keys(wallet.tokens).length})</h4>
                           <div style={PanelImageContainerStyle}>
                           {
                               Object.keys(wallet.tokens).filter((key) => {
                                   return filter(wallet.tokens[key]);
                               })
                               .map(function(key) {
                                   return (
                                       <TokenView key={key}
                                           token={wallet.tokens[key]}
                                           selected={creatorContext.selection.selectedIndex}
                                           click={() => creatorContext.selection.setSelected(key, "token", wallet.tokens[key])} />
                                   )
                               })
                           }
                           </div>
                       </div>
                   )
               })
           }
           </div>
       </div>
    )
}