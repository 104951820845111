import React, { useEffect, useState, useContext, useRef } from 'react';

import { useDrop } from 'react-dnd';

import CreatorContext from './CreatorContext';
import { checkContainment } from '../Utils/Utils';
import { ShowcaseDisplayPanelContainerStyle, ShowcaseDisplayPanelScrollArea } from './Styles';

export function ShowcaseDisplay({ dropLocId }) {
    const creatorContext = useContext(CreatorContext);

    var [hover, setHover] = useState(false);
    var [instances, setInstances] = useState(0);
    var [mouseInteractState, setMouseInteract] = useState({ state: "up" });
    var [currentPreview, setPreview] = useState({ });

    if (Object.keys(creatorContext.displays.displayInstances).length != instances) {
        setInstances(Object.keys(creatorContext.displays.displayInstances).length);
    }

    const currentLocation = creatorContext.showcase.editorFocus.location;

    const displayModels = [{value: "simple", title: "Simple"}];

    const updateHover = (item, monitor) => {
        if (!monitor.canDrop() || !monitor.isOver({shallow: true})) {
            return;
        }

        const displayInstance = creatorContext.displays.displayInstances[item.instanceId];

        // Get hover position
        const offset = monitor.getClientOffset();

        const element = document.getElementById(dropLocId);
        const elementOffset = {
            x: element.getBoundingClientRect().left,
            y: element.getBoundingClientRect().top,
            scrollX: element.scrollLeft,
            scrollY: element.scrollTop
        }

        const position = offset != null ? {
            x: offset.x - elementOffset.x + elementOffset.scrollX,
            y: offset.y - elementOffset.y + elementOffset.scrollY
        } : { }

        // Get expected bounds
        const location = creatorContext.showcase.current.locationAdapter.findLocation(currentLocation);
        var showcaseExpectedLocation = null;
        if (location) {
            const boxes = creatorContext.showcase.current.locationAdapter.getOccupiedRegions(
                { [location.id]: { exclude: [displayInstance.instanceId] } })
            showcaseExpectedLocation = location.findLocationForItem(
                { position },
                boxes,
                () => displayInstance.boundsForAttributes({ }))
        }

        var showcaseLocation = {
            position: position,
            bounds: showcaseExpectedLocation == null
                ? displayInstance.boundsForAttributes({ })
                : showcaseExpectedLocation.bounds,
        }

        const [canAccept, onSlot] = creatorContext.showcase.current.canAccept(displayInstance, currentLocation, null, null);

        if (canAccept) {
            setPreview({
                [displayInstance.instanceId]: {
                    display: displayInstance,
                    showcaseLocation: showcaseLocation,
                    expectedLocation: showcaseExpectedLocation,
                    slotName: onSlot
                }
            })
            setHover(true);
        }
    }

    const canAccept = (item) => {
        const displayInstance = creatorContext.displays.displayInstances[item.instanceId];
        if (displayInstance == null) {
            return false;
        }
        return displayInstance.displayParent == null &&
            creatorContext.showcase.current.canAccept(displayInstance, currentLocation, null, null);
    }

    const dropDisplayOnLocation = (item, monitor) => {
        const displayInstance = creatorContext.displays.displayInstances[item.instanceId];
        if (displayInstance == null) {
            return false;
        }

        const offset = monitor.getClientOffset();

        const element = document.getElementById(dropLocId);
        const elementOffset = {
            x: element.getBoundingClientRect().left,
            y: element.getBoundingClientRect().top,
            scrollX: element.scrollLeft,
            scrollY: element.scrollTop
        }

        const position = offset != null ? {
            x: offset.x - elementOffset.x + elementOffset.scrollX,
            y: offset.y - elementOffset.y + elementOffset.scrollY
        } : { }

        setPreview({ });
        // Figure out the drop position
        creatorContext.showcase.attachDisplay(displayInstance, { position }, currentLocation, null, null);
    }

    const useLeave = (callback, isOver, didDrop) => {
        const ref = useRef(isOver)

        useEffect(() => {
            if (ref.current && !isOver && !didDrop) {
                callback()
            }

            ref.current = isOver
        }, [hover, instances, isOver, didDrop])
    }

    const [{ isOver, canDrop, didDrop }, drop] = useDrop(
        () => ({
            accept: "display",
            drop: dropDisplayOnLocation,
            hover: updateHover,
            canDrop: canAccept,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop()
            })
        }), [hover, instances, creatorContext.showcase.interaction]
    )

    useLeave(() => setHover(false), isOver, didDrop);

    const itemMouseDown = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (mouseInteractState.state != "up")
            return;

        const scrollX = event.currentTarget.scrollLeft;
        const scrollY = event.currentTarget.scrollTop;

        const x = event.nativeEvent.pageX - event.currentTarget.offsetLeft + scrollX;
        const y = event.nativeEvent.pageY - event.currentTarget.offsetTop + scrollY;

        setMouseInteract({
            startX: x,
            startY: y,
            state: "down",
        })
    }

    const itemMouseMove = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const scrollX = event.currentTarget.scrollLeft;
        const scrollY = event.currentTarget.scrollTop;

        const x = event.nativeEvent.pageX - event.currentTarget.offsetLeft + scrollX;
        const y = event.nativeEvent.pageY - event.currentTarget.offsetTop + scrollY;

        if (mouseInteractState.state == "down") {
            // Dragging
            const boxes = creatorContext.showcase.current.locationAdapter.getOccupiedRegions({ [currentLocation]: { } });
            const selected = checkContainment(boxes)({
                    x: mouseInteractState.startX,
                    y: mouseInteractState.startY,
                    width: 1,
                    height: 1
                },
                true);

            if (selected.length == 0) {
                // Dragging a selection box
                setMouseInteract({
                    ...mouseInteractState,
                    currentX: x,
                    currentY: y,
                    state: "highlight"
                })
            } else {
                // Dragging a selected item
                const item = selected[0];

                const targetOffsetX = mouseInteractState.startX - item.x;
                const targetOffsetY = mouseInteractState.startY - item.y;

                setMouseInteract({
                    ...mouseInteractState,
                    targetOffsetX: targetOffsetX,
                    targetOffsetY: targetOffsetY,
                    currentX: x,
                    currentY: y,
                    item: item,
                    state: "move"
                })
            }
        } else if (mouseInteractState.state == "move") {
            const position = {
                x: x - mouseInteractState.targetOffsetX,
                y: y - mouseInteractState.targetOffsetY
            }

            // Get expected bounds
            const location = creatorContext.showcase.current.locationAdapter.findLocation(currentLocation);
            var showcaseExpectedLocation = null;
            if (location) {
                const boxes = creatorContext.showcase.current.locationAdapter.getOccupiedRegions({ [currentLocation]: { } })
                const index = boxes.findIndex((box) => box.id == mouseInteractState.item.id);
                const bounds = {
                    width: index >= 0 ? boxes[index].width : 1,
                    height: index >= 0 ? boxes[index].height : 1
                }

                showcaseExpectedLocation = location.findLocationForItem(
                    { position },
                    boxes.filter((box) => box.id != mouseInteractState.item.id),
                    () => bounds)

                var showcaseLocation = {
                    position: position,
                    bounds: bounds
                }

                setPreview({
                    [mouseInteractState.item.id]: {
                        showcaseLocation: showcaseLocation,
                        expectedLocation: showcaseExpectedLocation,
                    }
                })
            }

            setMouseInteract({
                ...mouseInteractState,
                currentX: x,
                currentY: y
            })
        } else {
            setMouseInteract({
                ...mouseInteractState,
                currentX: x,
                currentY: y
            })
        }
    }

    const itemMouseUp = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const scrollX = event.currentTarget.scrollLeft;
        const scrollY = event.currentTarget.scrollTop;

        const x = event.nativeEvent.pageX - event.currentTarget.offsetLeft + scrollX;
        const y = event.nativeEvent.pageY - event.currentTarget.offsetTop + scrollY;

        if (mouseInteractState.state == "down") {
            const boxes = creatorContext.showcase.current.locationAdapter.getOccupiedRegions({ [currentLocation]: { } });
            const selected = checkContainment(boxes)({
                x: mouseInteractState.startX,
                y: mouseInteractState.startY,
                width: 1,
                height: 1
            }, true);

            creatorContext.showcase.selectBounds(selected);
            setMouseInteract({ state: "up" })
        } else if (mouseInteractState.state == "move") {
            const boxes = creatorContext.showcase.current.locationAdapter.getOccupiedRegions({ [currentLocation]: { } })
                .filter((box) => box.id != mouseInteractState.item.id);

            const position = {
                x: x - mouseInteractState.targetOffsetX,
                y: y - mouseInteractState.targetOffsetY
            }
            const displayInstance = creatorContext.displays.displayInstances[mouseInteractState.item.id]
            setPreview({ })
            creatorContext.showcase.moveItemPosition(
                displayInstance,
                position,
                {
                    width: mouseInteractState.item.width,
                    height: mouseInteractState.item.height
                })
            setMouseInteract({ state: "up" })
        } else {
            creatorContext.showcase.selectBounds([]);
            setMouseInteract({ state: "up" })
        }
    }

    return (
        <div style={ShowcaseDisplayPanelContainerStyle}>
            <h3>Showcase Layout</h3>
            <select name="showcase-display-model"
             defaultValue="simple"
             style={{width: "100%"}}
             onChange={(control) =>
                 creatorContext.showcase.setDisplayModel(control.target.value)
             }>
            {
                  (displayModels.map((displayModel, index) =>
                    <option key={index + displayModel.value}
                            value={displayModel.value}>{displayModel.title}</option>
                  ))
            }
            </select>
            <div id={dropLocId}
             style={ShowcaseDisplayPanelScrollArea}
             ref={canDrop ? drop : null}
             onMouseDown={itemMouseDown}
             onMouseMove={itemMouseMove}
             onMouseUp={itemMouseUp}>
            {
                (() => {
                    if (currentLocation) {
                        const renderParams = {
                            locations: [{
                                id: currentLocation,
                            }],
                            commonAttributes: {
                                highlights: creatorContext.showcase.selectedBounds || null,
                                renderPreview: currentPreview
                            }
                        }

                        return creatorContext.showcase.current.locationAdapter.renderLocations(renderParams);
                    } else {
                        return  (
                            <div>
                                <p>No Location selected</p>
                            </div>
                        )
                    }
                })()
            }
            </div>
        </div>
    )
}