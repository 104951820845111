import React, { useEffect } from 'react';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { useSelector } from "react-redux";
import { WalletReducer, UserReducer } from './WalletsModel';
import { DisplayReducer } from './Display/DisplayModels';
import { ResolutionReducer } from './Utils/ConnectionResolution';
import { Router } from './MainRouter';
import { LoginAuthenticatedUser } from './UserLogin';
import { useHistory } from 'react-router';
import { setupMetaMask } from './Wallets/ConnectMetaMask';

const mainState = { };
const mainReducers = combineReducers({
    walletReducer: WalletReducer,
    userReducer: UserReducer,
    displayReducer: DisplayReducer,
    resolutionsReducer: ResolutionReducer
});

const middleware = [thunk];
const mainStore = createStore(
    mainReducers,
    mainState,
    applyMiddleware(...middleware)
);

export { mainStore };

function App() {
    const userReducer = useSelector((state) => state.userReducer );
    const { userId } = userReducer;

    const history = useHistory();
    LoginAuthenticatedUser({redirectTo: '/', history: history})

    useEffect(() => {
        setupMetaMask();
    }, [])

    return (userId == null) ? (<div></div>) : (<Router />)
}

export default App;
