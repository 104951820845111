import React, { useEffect, useState, useContext, useRef } from 'react';

import { useDrag, useDrop } from 'react-dnd';

import CreatorContext from './CreatorContext';
import { PanelContainerStyle, PanelScrollAreaStyle, DisplayPanelImageContainerStyle, DisplayItemStyle, ItemSelected, ItemPreview } from './Styles';

export function DisplayView(pars) {
    const isInstance = pars.display.attachableDisplay.id != pars.display.instanceId;
    const isDraggable = isInstance && pars.display.displayParent == null;

    const creatorContext = useContext(CreatorContext);

    var displayStyle = {
        position: 'relative'
    }
    if (pars.selected(pars.display.instanceId, "display") != -1) {
        displayStyle = {
            ...displayStyle,
            ...ItemSelected
        };
    }

    var [hoverPreview, setHoverPreview] = useState(null);
    const updateHoverPreview = (item, monitor) => {
        if (!monitor.canDrop()) {
            return;
        }

        const hoverDisplay = creatorContext.displays.displayInstances[item.instanceId];

        if (hoverDisplay == null) {
            return;
        }

        const selectedDestination = creatorContext.displayAttributes.selectedDestination(pars.display);
        if (selectedDestination == null) {
            return;
        }

        setHoverPreview({
            [selectedDestination.slotName]: [...(new Array(selectedDestination.slotIndex).fill(null)), {
                attachment: hoverDisplay.destinations[selectedDestination.destination] != null
                                ? hoverDisplay.destinations[selectedDestination.destination][selectedDestination.destinationIndex]
                                : null,
                attributes: { /* TODO: Once we have an attribute editor */},
                asDestination: selectedDestination.destination
            }]
        });
    };

    const dropDisplay = (item, monitor) => {
        const droppedDisplay = creatorContext.displays.displayInstances[item.instanceId];

        if (droppedDisplay == null) {
            return;
        }

        pars.addChildDisplay(pars.display, droppedDisplay);
    }

    const canDropDisplay = (item) => {
        const hoverDisplay = creatorContext.displays.displayInstances[item.instanceId];

        if (hoverDisplay == null) {
            return;
        }

        return pars.display.canAttach != null &&
            pars.display.canAttach(null, hoverDisplay);
    }

    const useLeave = (callback, isOver, didDrop) => {
        const ref = useRef(isOver)

        useEffect(() => {
            if (ref.current && !isOver && !didDrop) {
                callback()
            }

            ref.current = isOver
        }, [hoverPreview, isOver, didDrop])
    }

    const [{ isDragging }, drag, preview] = useDrag(() => ({
        type: "display",
        item: { instanceId: pars.display.instanceId },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    }))

    const [{ isOver, canDrop, didDrop }, drop] = useDrop(
        () => ({
            accept: "display",
            drop: dropDisplay,
            hover: updateHoverPreview,
            canDrop: canDropDisplay,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop()
            })
        }), [hoverPreview]
    )

    useLeave(() => setHoverPreview(null), isOver, didDrop);

    if (isOver && canDrop) {
        displayStyle = {
            ...displayStyle,
            outlineColor: 'green',
            outlineStyle: 'solid'
        };
    }

    const renderParams = {
        renderMode: "preview",
        renderSize: { width: 100, height: 100 },
        previewElements: hoverPreview || pars.previews
    };

    return (
        <div key={pars.display.instanceId} style={{marginRight: "20px", position: "relative"}}>
            <p>{pars.display.instanceName}</p>
            <div ref={preview} style={ItemPreview}></div>
            <div id={"[display]"+pars.display.instanceId}
                ref={canDrop ? drop : isDraggable ? drag : null}
                style={displayStyle}
                onClick={pars.click}>
                <div style={DisplayItemStyle}>
                    {pars.display.renderInstance(renderParams)}
                </div>
            </div>
            {
                (() => {
                    if (pars.slotInfo
                        && pars.slotInfo.available
                        && pars.slotInfo.selectedSlotIndex != null) {
                        const slotIndex = pars.slotInfo.selectedSlotIndex;
                        const selectedSlot = pars.slotInfo.available[slotIndex];
                        return (
                            <div>
                                <select name="available-slots"
                                 defaultValue={selectedSlot.onSlotNamed}
                                 onChange={(control) =>
                                     pars.slotInfo.updateSelection(
                                        pars.display.instanceId,
                                        parseInt(control.target.value, 0),
                                        0,
                                        pars.slotInfo.destinationIndex)
                                 }>
                                {
                                      (pars.slotInfo.available.map((slot, currentIndex) =>
                                        slot.atSlotIndices.length > 0
                                            ? <option key={slot.onSlotNamed + currentIndex}
                                                value={currentIndex}>{slot.onSlotNamed}</option>
                                            : null))
                                }
                                </select>
                                <input key={pars.display.instanceId}
                                    type="number"
                                    name="slotIndex"
                                    min="0"
                                    max={selectedSlot.atSlotIndices.length - 1}
                                    defaultValue="0"
                                    onChange={(control) =>
                                        pars.slotInfo.updateSelection(
                                            pars.display.instanceId,
                                            slotIndex,
                                            parseInt(control.target.value, 0),
                                            pars.slotInfo.destinationIndex)}></input>
                                <p style={{lineHeight: "0"}}>[{pars.slotInfo.indexOnSlot}]</p>
                            </div>
                        )
                    }
                })()
            }
        </div>
    )
}

export function DisplaySidebar() {
    const creatorContext = useContext(CreatorContext);

    const displayGroupRank = ["unattached", "displays"];
    var displayGroups = [];

    // Set a filter display is selected first
    var selectionFilter = null;
    var filterAgainst = null;
    const displaySelectionIndex = creatorContext.selection.selected.findIndex((item) => item.item == "display");
    if (displaySelectionIndex - 1 >= 0) { // Selected, but not first selected
        filterAgainst = creatorContext.selection.selected[displaySelectionIndex - 1];
    } else if (creatorContext.selection.selected.length > 0) {
        filterAgainst = creatorContext.selection.selected[creatorContext.selection.selected.length - 1];
    }

    if (filterAgainst) {
        switch(filterAgainst.item) {
            case "token":
                const token = filterAgainst.object;
                selectionFilter = { token: token };
                break;
            default: break;
        }
    }

    const filter = (display) => {
        if (selectionFilter) {
            if (selectionFilter.token == null ||
                (display.hasOwnProperty("canAccept") && !display.canAccept(selectionFilter.token)) ||
                (display.hasOwnProperty("canAttach") && !display.canAttach(null, selectionFilter.token))) { // TODO: Add attribute values once available
                return false;
            }
        }

        return true;
    };

    var selectedToken = null;
    const tokenSelectionIndex = creatorContext.selection.selected.findIndex((item) => item.item == "token");
    if (tokenSelectionIndex >= 0) {
        selectedToken = creatorContext.selection.selected[tokenSelectionIndex].object;
    }

    const siblings = function(left, right) {
        var currentItem = left;
        const parent = right.displayParent;

        while (currentItem != null ) {
            if (currentItem.displayParent == parent) {
                return true;
            } else {
                currentItem = currentItem.displayParent;
            }
        }

        return false;
    }

    displayGroupRank.forEach((group) => {
        switch (group) {
            case "unattached":
                if (Object.keys(creatorContext.displays.displayInstances).length != 0) {
                    const values = Object.keys(creatorContext.displays.displayInstances).filter((key) =>
                        filter(creatorContext.displays.displayInstances[key])
                    ).map((key) =>
                        creatorContext.displays.displayInstances[key]
                    ).sort((leftItem, rightItem) => {
                        if (rightItem.displayParent == leftItem) {
                            return -1;
                        } else if (leftItem.displayParent == rightItem) {
                            return 1;
                        } else if (siblings(leftItem, rightItem)) {
                            return 0;
                        } else {
                            return leftItem.hasChildDisplayInstances() - rightItem.hasChildDisplayInstances();
                        }
                    });
                    displayGroups.push({id: group, title: "Unattached displays", items: values});
                }
                break;
            case "displays":
                if (Object.keys(creatorContext.displays.attachableDisplays).length != 0) {
                    const values = Object.keys(creatorContext.displays.attachableDisplays).filter((key) =>
                        filter(creatorContext.displays.attachableDisplays[key])
                    ).map((key) => {
                        const display = creatorContext.displays.attachableDisplays[key];
                        return display.createDisplayInstance(display.internalId, display.displayName, { });
                    });
                    displayGroups.push({id: group, title: "Start with", items: values});
                }
                break;
            default: break;
        }
    })

    var renderBlock = false

    return (
        <div style={PanelContainerStyle}>
            <h3>Token Displays</h3>
            <div style={PanelScrollAreaStyle}>
            {
                displayGroups.map((group) => {
                    return (
                        <div key={group.id} style={{textAlign: "center"}}>
                            <h4>{group.title} ({group.items.length})</h4>
                            <div style={DisplayPanelImageContainerStyle}>
                            {
                                group.items.map((display) => {
                                    // Figure out the preview element when selected
                                    var previewElements = null;
                                    var displayAttachment = null;
                                    var availability = display.attachmentAttributes();
                                    displayAttachment = {
                                        available: availability
                                    }
                                    if (selectedToken) {
                                        // Get the selected destination item for the display
                                        const selectedDestination = creatorContext.displayAttributes.selectedDestination(display)
                                        if (selectedDestination) {
                                            const attachment = selectedToken.destinations[selectedDestination.destination] != null
                                                ? selectedToken.destinations[selectedDestination.destination][selectedDestination.destinationIndex]
                                                : null

                                            previewElements = { };
                                            previewElements[selectedDestination.slotName] = new Array(selectedDestination.slotIndex + 1).fill(null);
                                            previewElements[selectedDestination.slotName][selectedDestination.slotIndex]
                                                = {
                                                    attachment: attachment,
                                                    attributes: { /* TODO: Once we have an attribute editor */},
                                                    asDestination: selectedDestination.destination
                                                };

                                            displayAttachment = {
                                                ...displayAttachment,
                                                selectedSlotIndex: availability.findIndex((slot) =>
                                                    slot.onSlotNamed == selectedDestination.slotName),
                                                indexOnSlot: selectedDestination.slotIndex,
                                                destinationIndex: selectedDestination.destinationIndex,
                                                updateSelection: creatorContext.displayAttributes.setSelection
                                            }
                                        }
                                    }

                                    return (
                                        <DisplayView key={display.instanceId}
                                            display={display}
                                            selected={creatorContext.selection.selectedIndex}
                                            click={() => creatorContext.selection.setSelected(display.instanceId, "display", display)}
                                            addChildDisplay={creatorContext.addChildDisplay}
                                            previews={previewElements}
                                            slotInfo={displayAttachment} />)
                                })
                            }
                            </div>
                        </div>
                    )
                })
            }
            </div>
        </div>
    )
}