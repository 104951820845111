import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { uuidv4, WalletToken } from '../WalletsModel';
import { ADD_DISPLAY_INSTANCE, ADD_SHOWCASE } from '../Display/DisplayModels';
import { TokenSidebar } from './Tokens';
import { DisplaySidebar } from './Displays';
import { LocationSidebar } from './Locations';
import { ShowcaseDisplay } from './ShowcaseDisplay';

import SimpleShowcaseDisplay from '../Showcase/Models/SimpleShowcaseDisplay';
import { appendDisplayItem, attachDisplayItemTo, detachDisplayItem, getShowcase, resolveAllDisplayItems, saveShowcase, updateDisplayItem } from '../GraphQL/Showcase';

import CreatorContext from './CreatorContext';

import { CreatorHeader, Actions } from './Styles';
import DisplayInstance from '../Display/DisplayInstance';
import { Showcase } from '../Showcase/Showcase';

function CreatorLayout({showcase}) {
    const dispatch = useDispatch();
    const walletReducer = useSelector((state) => state.walletReducer );
    const displaysState = useSelector((state) => state.displayReducer);
    const userReducer = useSelector((state) => state.userReducer );
    const { repository } = walletReducer;
    const { userId } = userReducer;
    const [selected, setSelected] = useState([]);
    const [displayAttributes, setDisplayAttributes] = useState({selected: { }});
    const [showcaseInteraction, setShowcaseInteraction] = useState(0);
    const [editorFocus, setEditorFocus] = useState({ });
    var [selectedBounds, setSelectedBounds] = useState([]);

    //  Update the selected item
    const updateSelection = function (id, item, object) {
        // TODO: Initial logic does not handle resets as yet
        const newItem = {id: id, item: item, object: object};

        const selectedIndexOfType = selected.findIndex((value) => value.item == item);

        if (selectedIndexOfType >= 0) {
            var items = new Array(...selected);
            items[selectedIndexOfType] = newItem;
            setSelected(items);
        } else {
            setSelected([...selected, newItem]);
        }
    }

    // Check if item is selected
    const selectionIndex = function(id, item) {
        return selected.findIndex((value) => value.id == id && value.item == item);
    }

    const setSelection = function(id, slotIndex, indexOnSlot, destinationIndex) {
        setDisplayAttributes({
            ...displayAttributes,
            selected: {
                ...displayAttributes.selected,
                [id]: { slotIndex, indexOnSlot, destinationIndex }
            }
        })
    }

    const getSelection = function(id) {
        return displayAttributes.selected[id];
    }

    const clearSelection = function(id) {
        var selected = displayAttributes.selected;
        delete selected[id];

        setDisplayAttributes({
            ...displayAttributes,
            selected: selected
        })
    }

    const getSelectedDestination = function(display) {
        const availability = display.attachmentAttributes();
        var selected = displayAttributes.selected[display.instanceId];

        if (availability.length == 0) {
            return null;
        }

        if (selected == null) {
            const slotIndex = availability.findIndex((item) => item.atSlotIndices.length > 0);
            if (slotIndex < 0) {
                return null;
            }

            selected = {
                slotIndex: slotIndex,
                indexOnSlot: 0,
                destinationIndex: 0
            }
        }

        // TODO: Non default destination index
        return {
            slotName: availability[selected.slotIndex].onSlotNamed,
            slotIndex: availability[selected.slotIndex].atSlotIndices[selected.indexOnSlot],
            destination: availability[selected.slotIndex].fromDestination,
            destinationIndex: selected.destinationIndex
        }
    }

    const createOrUpdateDisplayInstance = function() {
        const displaySelectionIndex = selected.findIndex((item) => item.item == "display")
        if (displaySelectionIndex < 0) {
            return;
        }
        const selectedDisplay = selected[displaySelectionIndex].object;
        const tokenSelectionIndex = selected.findIndex((item) => item.item == "token");
        if (tokenSelectionIndex < 0) {
            return;
        }
        const selectedToken = selected[tokenSelectionIndex].object;

        const selectedSlotInfo = getSelectedDestination(selectedDisplay);

        // Create a new instance if we are not updating
        var display = selectedDisplay;
        var isCreate = false;
        if (displaysState.attachableDisplays.hasOwnProperty(display.instanceId)) {
            // TODO: Count number of instance types to get instance name
            display = display.attachableDisplay.createDisplayInstance(uuidv4(), display.attachableDisplay.displayName);
            isCreate = true;
        }

        // TODO: Once editor is created we can attach the expected attributes here
        var attributes = { }

        if (display.attach(attributes,
            selectedToken,
            selectedSlotInfo.slotName,
            selectedSlotInfo.slotIndex,
            selectedSlotInfo.destination,
            selectedSlotInfo.destinationIndex)) {
            setSelected([]);

            clearSelection(selectedDisplay.instanceId);

            dispatch({
                type: ADD_DISPLAY_INSTANCE,
                payload: display
            })

            if (isCreate) {
                dispatch(appendDisplayItem(display, () => attributes));
            } else {
                dispatch(updateDisplayItem(display, () => attributes));
            }
        }
    }

    // Parent - Child displays

    const handleAddChild = function(parent, child) {
        // TODO: Add attributes once there is a functional editor
        const attributes = { };
        let selectedSlotInfo = getSelectedDestination(parent);

        const isAttached = parent.attach(attributes,
            child,
            selectedSlotInfo.slotName,
            selectedSlotInfo.slotIndex,
            selectedSlotInfo.destination,
            selectedSlotInfo.destinationIndex);

        if (!isAttached) 
            return

        if (child instanceof DisplayInstance) {
            dispatch(attachDisplayItemTo(child, () => attributes));
        } else if (child instanceof WalletToken) {
            // TODO: Attach Token remotely
        }

        clearSelection(parent.instanceId);
        clearSelection(child.instanceId);
        
        setSelected([]);
    }

    // Showcases

    // TODO: Add a display model switcher so that a model can switch to support display models


    // Use a showcase interaction state to refresh showcase on changes
    const updateShowcaseInteraction = () => {
        setShowcaseInteraction(showcaseInteraction + 1);
    }

    const attachToShowcase = (display, showcaseLocation, locationId, slotName, index) => {
        creatorContext.showcase.current.attachDisplayInstance(display, showcaseLocation, locationId, slotName, index);
        dispatch(attachDisplayItemTo(display, creatorContext.showcase.current.id));
        updateShowcaseInteraction();
    }

    const moveItemPosition = (display, position, bounds, scale) => {
        const [moved, updatedLocation] = creatorContext.showcase.current.updateDisplayInstance(display, { position, bounds, scale });

        if (moved) {
            const updatedBox = {
                id: display.instanceId,
                x: updatedLocation.position.x,
                y: updatedLocation.position.y,
                width: updatedLocation.bounds.width,
                height: updatedLocation.bounds.height,
            }
            const selectedIndex = selectedBounds.findIndex((box) => box.id == updatedBox.id);
            if (selectedIndex >= 0) {
                var toUpdate = selectedBounds;
                toUpdate[selectedIndex] = updatedBox;
                setSelectedBounds(toUpdate);
            }
            updateShowcaseInteraction();
        }
    }

    const detachDisplay = (display) => {
        creatorContext.showcase.current.detachDisplayInstance(display);
        dispatch(detachDisplayItem(display));
        updateShowcaseInteraction();
    }

    const setDisplayModel = (displayModel) => {
        // TODO: Find referenced display model to set to the showcase
        updateShowcaseInteraction();
    }

    const updateShowcaseTitle = (title) => {
        creatorContext.showcase.current.title = title;
        updateShowcaseInteraction();
    }

    const updateShowcasePath = (path) => {
        creatorContext.showcase.current.path = path;
        updateShowcaseInteraction();
    }

    const saveCurrentShowcase = () => {
        dispatch(saveShowcase(creatorContext.showcase.current));
    }

    const selectFocus = (focus) => {
        setEditorFocus(focus);

        updateShowcaseInteraction();
    }

    const creatorContext = {
        repository: repository,

        displays: displaysState,
        selection: {
            selected: selected,
            selectedIndex: selectionIndex,
            setSelected: updateSelection
        },
        displayAttributes: {
            selection: getSelection,
            setSelection: setSelection,
            selectedDestination: getSelectedDestination
        },
        addChildDisplay: handleAddChild,
        showcase: {
            current: showcase,
            interaction: showcaseInteraction,
            updateShowcase: updateShowcaseInteraction,
            attachDisplay: attachToShowcase,
            setDisplayModel: setDisplayModel,
            editorFocus: editorFocus,
            selectFocus: selectFocus,
            selectedBounds: selectedBounds,
            selectBounds: setSelectedBounds,
            moveItemPosition: moveItemPosition,
            detachDisplay: detachDisplay
        }
    }

    useEffect(() => {
        if (userId) {
            dispatch(repository.resolveAllWallets(true));
            dispatch(resolveAllDisplayItems());
        }
    }, [userId, repository == null])

    return (
        <div>
            <CreatorContext.Provider value={creatorContext}>
                <div style={CreatorHeader}>
                    <h2>Create showcase from wallet tokens</h2>
                    <div style={{float: "left"}}>
                        <p>Title:</p>
                        <input type="text" onChange={(event) => updateShowcaseTitle(event.target.value)}
                        value={creatorContext.showcase.current.title}>
                        </input>
                        <p>Path:</p>
                        <input type="text" onChange={(event) => updateShowcasePath(event.target.value)}
                        value={creatorContext.showcase.current.path}>
                        </input>
                    </div>
                    <div style={Actions}>
                        <button onClick={() => saveCurrentShowcase()}>Save Showcase</button>
                        <button disabled={selected.length < 2} onClick={() => createOrUpdateDisplayInstance()}>
                        {
                            (() => {
                                const displaySelectionIndex = selected.findIndex((item) => item.item == "display")
                                if (displaySelectionIndex < 0 ||
                                    displaysState.attachableDisplays.hasOwnProperty(
                                        selected[displaySelectionIndex].object.instanceId)) {
                                    return 'Add Display Instance';
                                } else {
                                    return 'Update Display Instance';
                                }
                            })()
                        }
                        </button>
                        <button disabled={selected.length == 0} onClick={() => setSelected([])}>Clear Selection</button>
                    </div>
                </div>
                <div style={{display: "block", clear: "both", visibility: "hidden"}}></div>
                <div style={{display: "flex"}}>
                    <DndProvider backend={HTML5Backend}>
                        <ShowcaseDisplay dropLocId="creator-location-drop" />
                        <LocationSidebar />
                        <DisplaySidebar />
                        <TokenSidebar />
                    </DndProvider>
                </div>
            </CreatorContext.Provider>
        </div>
    )
}

export default function ShowcasesCreator() {
    const { showcase_id } = useParams();
    const [layoutState, setState] = useState({id: showcase_id, showcase: null});
    const displaysState = useSelector((state) => state.displayReducer);
    const dispatch = useDispatch();

    return useMemo(() => {
        if (layoutState.id == undefined) {
            console.log("Create new showcase")
            const id = uuidv4();
            const modelDisplay = new SimpleShowcaseDisplay();
            modelDisplay.defaultLocations();
            const showcase = new Showcase(id, modelDisplay);
            dispatch({
                type: ADD_SHOWCASE,
                payload: showcase
            })
            setState({id, showcase})
        } else if (layoutState.showcase == null) {
            const currentShowcase = displaysState.showcases[layoutState.id];
            if (currentShowcase != null) {
                console.log("Use existing showcase")
                dispatch(getShowcase(currentShowcase.id))
                setState({
                    ...layoutState,
                    showcase: displaysState.showcases[layoutState.id]
                })
            } else if (showcase_id != undefined) {
                console.log("Load in new showcase")
                dispatch(getShowcase(showcase_id));
                return (
                    <div><h1>Loading Showcase</h1></div>
                )
            }
        } else if (layoutState.showcase != null && layoutState.showcase.display != null) { 
            return (
                <CreatorLayout showcase={layoutState.showcase}/>
            )
        } else {
            return (
                <div><h1>Loading Showcase</h1></div>
            )
        }
    }, [dispatch, layoutState.showcase, layoutState.showcase != null && layoutState.showcase.display != null]);
}