import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getShowcaseAndResources } from "../GraphQL/Showcase";
import { GUEST_USER } from "../WalletsModel";

export function ShowcaseViewer({ showcase }) {
    if (showcase == null || showcase.display == null) {
        return (
            <div key={"Loading_id"}>
                <p>Loading Showcase</p>
            </div>
        )
    } else {
        return (
            <div key={showcase.id}>
                {
                    showcase.render(showcase.defaultRenderParameters())
                }
            </div>
        )
    }
}

export function ShowcaseLoaderView() {
    const { showcase_id } = useParams();
    const [showcase, setShowcase] = useState(null);
    const displaysState = useSelector(state => state.displayReducer);
    const repoState = useSelector(state => state.walletReducer);
    const resolutionState = useSelector(state => state.resolutionsReducer);
    const userState = useSelector(state => state.userReducer);
    const { userId } = userState;
    const dispatch = useDispatch();

    useEffect(() => {
        if (showcase == null) {
            if (displaysState.showcases.hasOwnProperty(showcase_id)) {
                setShowcase(displaysState.showcases[showcase_id])
            } else {
                console.log("Load in new showcase")
                dispatch(getShowcaseAndResources(showcase_id, userId != GUEST_USER));
            }
        }
    }, [showcase, displaysState.displayInstances, dispatch])

    const viewer = useMemo(() => {
        return (
            <ShowcaseViewer showcase={showcase} />
        )
    }, 
    [
        Object.keys(displaysState.displayInstances), 
        Object.keys(repoState.repository ? repoState.repository.tokens : []),
        Object.keys(resolutionState.resolutionsMap),
        dispatch
    ])

    return viewer;
}