export const CreatorHeader = {
    width: "100%",
    display: "inline-block",
    position: "relative"
}

export const Actions = {
    display: "inline-block",
    right: "5px",
    position: "absolute",
    top: "50%",
    transform: "translate(0,-50%)"
}

export const PanelContainerStyle = {
    flexShrink: "0",
    width: "250px",
}

export const ShowcaseDisplayPanelContainerStyle = {
    flexGrow: "1",
    overflow: "hidden"
}

export const PanelScrollAreaStyle = {
    height: "650px",
    overflowY: "scroll",
    padding: "4px"
}

export const ShowcaseDisplayPanelScrollArea = {
    height: "650px",
    overflowY: "scroll",
    overflowX: "scroll",
}

export const PanelImageContainerStyle = {
    MozColumnCount: "3",
    MozColumnGap: "4px",
    MozColumnWidth: "30%",
    WebkitColumnCount: "3",
    WebkitColumnGap: "4px",
    WebkitColumnWidth: "30%",
    columnCount: "3",
    columnGap: "4px",
    columnWidth: "30%",
    WebkitColumnSpan: "all",
    MozColumnSpan: "all",
    columnSpan: "all"
}

export const DisplayPanelImageContainerStyle = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
}

export const TokenImageStyle = {
    minHeight: "100%",
    width: "80px",
    verticalAlign: "middle"
}

export const DisplayItemStyle = {
    minHeight: "100px",
    width: "100px",
    outlineStyle: "dotted",
    flexWrap: "wrap",
    verticalAlign: "middle",
}

export const ItemSelected = {
    outlineStyle: "inset",
    outlineColor: "red",
    zIndex: "100",
}

export const ItemPreview = {
    outlineStyle: "solid",
    outlineColor: "LightBlue",
    outlineWidth: "1px",
    width: "5px",
    height: "5px",
    backgroundColor: "PowderBlue"
}

export const ShowcaseDetail = {
    display: "inline-block",
    width: "350px"
}

export const ShowcaseDetailShort = {
    display: "inline-block",
    width: "150px"
}