import { resolveSerializedObject, serializedTypeObject } from '../Utils/Utils';
import AttachableDisplay from './AttachableDisplay';
import { DestinationComponents } from './DestinationComponents';

export default class DisplayInstance {
    instanceId
    instanceName
    displayParent
    attachableDisplay: AttachableDisplay
    attachmentMap // Destination items that are attached to a slot with each of it's given attribute values.
    //  Attribute values do not change during the operation, additional values will not to be added by child class
    destinations // So that attached items can chain, they each specify their own destinations as well

    constructor(instanceId, instanceName, attachableDisplay: AttachableDisplay) {
        this.instanceId = instanceId;
        this.instanceName = instanceName;
        this.attachableDisplay = attachableDisplay;
        this.attachmentMap = { }
        this.destinations = { };
    }

    // There could be a difference when attaching items. We could be matching all possible destinations with the
    // display stated destinations, however we may be attaching with the actual destination parameter map
    canAttach(attributeValues, attachment, slotName, asDestination) {
        // Don't add something that already has a parent
        if (attachment.displayParent != null) {
            return false;
        }

        var checkSlots;
        if (slotName != null) {
            checkSlots = [slotName];
        } else {
            checkSlots = Object.keys(this.attachableDisplay.attachmentSlots);
        }

        for (let slot of checkSlots) {
            if (!this.attachableDisplay.canAccept(asDestination || attachment, slot)) {
                continue;
            }

            // Ensure item can be accepted at a destination
            if (asDestination && attachment.destinations) {
                if (attachment.destinations[asDestination] == null) {
                    continue;
                }
            }

            // check allocated capacity
            var capacity = this.attachableDisplay.attachmentSlots[slot].capacity;

            if (capacity == null) {
                capacity = 1;
            } else if (capacity == "inf") {
                return true;
            }

            // Get current items
            if (!this.attachmentMap.hasOwnProperty(slot)) {
                if (capacity > 0) {
                    return true;
                }
            } else {
                if (this.attachmentMap[slot].length < capacity ||
                    this.attachmentMap[slot].indexOf(null) >= 0) {
                    return true;
                }
            }
        }

        return false;
    }

    // Take an attachable item and look through the attachment locations it can fit into
    attachmentAttributes(attachment, slotName) {
        var checkSlots;
        if (slotName != null) {
            checkSlots = [slotName];
        } else {
            checkSlots = Object.keys(this.attachableDisplay.attachmentSlots);
        }

        // {onSlotNamed: String, atSlotIndex: [Int], fromDestination: String}
        var attachableResults = [];

        var destinationNames = null;
        if (attachment && attachment.destinations) {
            var destinationNames = Object.keys(attachment.destinations);
        }
        const destinationLookup = destinationNames != null;
        for (let slot of checkSlots) {
            // check allocated capacity
            var capacity = this.attachableDisplay.attachmentSlots[slot].capacity;

            if (capacity == null) {
                capacity = 1;
            }

            if (!destinationLookup) {
                destinationNames = this.attachableDisplay.attachmentSlots[slot].accepts.map(x => x);
            }

            for (let destination of destinationNames) {
                if (destinationLookup && !this.attachableDisplay.canAccept(destination, slot)) {
                    continue;
                }
                if (!this.attachmentMap.hasOwnProperty(slot)) {
                    attachableResults.push({
                        onSlotNamed: slot,
                        atSlotIndices: capacity == "inf" ? [0] : [...Array(capacity).keys()],
                        fromDestination: destination
                    });
                } else {
                    var endIndex = capacity == "inf" ? this.attachmentMap[slot].length : capacity;
                    var slotIndices = [];
                    for (let index = 0; index < endIndex; index++) {
                        if (this.attachmentMap[slot][index] == null) {
                            slotIndices.push(index);
                        }
                    }
                    attachableResults.push({
                        onSlotNamed: slot,
                        atSlotIndices: slotIndices,
                        fromDestination: destination
                    });
                }
            }
        }

        return attachableResults;
    }

    attach(attributeValues, item, slotName, atSlotIndex, asDestination, destinationIndex) {
        if (item.displayParent != null) {
            return false;
        }

        if (!this.canAttach(attributeValues, item, slotName, asDestination)) {
            return false;
        }

        // Init the slot map if empty
        if (!this.attachmentMap.hasOwnProperty(slotName)) {
            this.attachmentMap[slotName] = [];
        }

        while (this.attachmentMap[slotName].length < atSlotIndex) {
            this.attachmentMap[slotName].push(null);
        }

        const attachment = item.destinations[asDestination][destinationIndex];
        this.attachmentMap[slotName][atSlotIndex] = { attachment, attributeValues, asDestination, destinationIndex };

        attachment.source = item;
        attachment.displayParent = this;
        item.displayParent = this;

        return true;
    }

    hasChildDisplayInstances() {
        const attachmentSlots = Object.keys(this.attachmentMap);
        for (let slot of attachmentSlots) {
            if (this.attachmentMap[slot].findIndex((item) => item != null ? item.attachment instanceof DisplayInstance : false)) {
                return true
            }
        }

        return false
    }

    detachAttachment(attachment) {
        for (let key of Object.keys(this.attachmentMap)) {
            const itemIndex = this.attachmentMap[key].findIndex((item) =>
                (item.attachment === attachment || item.attachment.instanceId == attachment.instanceId));
            if (itemIndex > -1) {
                this.attachmentMap[key].splice(itemIndex, 1);
                return true;
            }
        }

        return false;
    }

    detachFromSlot(slotName, attributes) { // Remove the items that match the attributes on the given slot
        //  TODO: Figure out the attribute matching scheme. For now this will delete first item encountered when attributes is null
        var checkSlots;
        if (slotName != null) {
            checkSlots = [slotName];
        } else {
            checkSlots = Object.keys(this.attachableDisplay.attachmentSlots);
        }

        for (let key of checkSlots) {
            const itemIndex = this.attachmentMap[key].findIndex((item) => (attributes == item.attributeValues));
            if (itemIndex > -1) {
                this.attachmentMap[key].splice(itemIndex, 1);
                return true;
            }
        }
    }

    serialize() {
        // Store every slot entry as a list container attachment type and id + attributes + destination 
        var attachments = { }
        Object.keys(this.attachmentMap).forEach(key => {
            attachments[key] = [];

            this.attachmentMap[key].forEach(entry => {
                if (entry != null) {
                    const object = serializedTypeObject(entry.attachment.source);
                    attachments[key].push({
                        object: object || "NULL",
                        attributes: entry.attributeValues,
                        destination: entry.asDestination,
                        destinationIndex: entry.destinationIndex
                    })
                } else {
                    attachments[key].push("NULL");
                }
            })
        });

        var serialized = {
            attachments
        }

        return serialized;
    }

    deserialize(serialized, dispatch, getState) {
        // Resolve the attached items ids
        var unresolved = [];
        if (serialized.attachments) {
            for (const slotName of Object.keys(serialized.attachments)) {
                serialized.attachments[slotName].forEach((entry, index) => {
                    if (entry == "NULL") {
                        return;
                    }

                    if (!this.attachmentMap.hasOwnProperty(slotName)) {
                        this.attachmentMap[slotName] = [];
                    }

                    while (this.attachmentMap[slotName].length < index) {
                        this.attachmentMap[slotName].push(null);
                    }

                    const [resolved] = resolveSerializedObject(entry.object, dispatch, getState, (resolvedAttachment) => {
                        const destinationComponent = resolvedAttachment.destinations[entry.destination][entry.destinationIndex];
                        this.attachmentMap[slotName][index] = { 
                            attachment: destinationComponent,
                            attributeValues : entry.attributes,
                            asDestination : entry.destination,
                            destinationIndex : entry.destinationIndex
                        };
                    
                        destinationComponent.source = resolvedAttachment;
                        destinationComponent.displayParent = this;
                    })

                    if (!resolved) {
                        // Insert display item placeholder                        
                        const placeholder = DestinationComponents.__DISPLAY_PLACEHOLDER__;
                        this.attachmentMap[slotName][index] = { 
                            attachment: {
                                destinationComponent: placeholder.bind(entry.attributes)
                            }, 
                            attributeValues : entry.attributes,
                            asDestination : entry.destination,
                            destinationIndex : entry.destinationIndex };
                
                        placeholder.source = null;
                        placeholder.displayParent = this;

                        unresolved.push(entry.object);
                    }
                })
            }
        }

        return unresolved;
    }

    renderAttachmentsWithParams(renderParams) {
        var previewOverrideMap = { }

        // Render step allows for preview overrides
        if (renderParams.renderMode && renderParams.renderMode == "preview") {
            const previewElements = renderParams.previewElements;

            if (previewElements) {
                previewOverrideMap = previewElements;
            }
        }

        var renderOrder = renderParams.renderOrder;
        if (renderOrder == null) {
            renderOrder = Object.keys(this.attachableDisplay.attachmentSlots);
        }

        return (renderOrder.map((attachmentSlot) => {
            // TODO: Figure out a way to put the attachment/destination type in the attachment
            var slotEntries = [];
            const hasAttachmentsAtSlotName = this.attachmentMap.hasOwnProperty(attachmentSlot);
            if (previewOverrideMap.hasOwnProperty(attachmentSlot)) {
                const maxLength = Math.max(previewOverrideMap[attachmentSlot].length,
                    hasAttachmentsAtSlotName ? this.attachmentMap[attachmentSlot].length : 0)
                for (let index = 0; index < maxLength; index++) {
                    if (index < previewOverrideMap[attachmentSlot].length &&
                        previewOverrideMap[attachmentSlot][index] != null) {
                        slotEntries.push(previewOverrideMap[attachmentSlot][index]);
                    } else if (hasAttachmentsAtSlotName &&
                        index < this.attachmentMap[attachmentSlot].length) {
                        slotEntries.push(this.attachmentMap[attachmentSlot][index])
                    } else {
                        slotEntries.push(null);
                    }
                }
            } else if (hasAttachmentsAtSlotName) {
                slotEntries = this.attachmentMap[attachmentSlot];
            }

            return this.attachableDisplay.handleInstanceSlotName(renderParams, attachmentSlot, slotEntries);
        }));
    }

    renderInstance(params) {
        return this.attachableDisplay.handleInstanceRoot(params, this.renderAttachmentsWithParams.bind(this));
    }

    boundsForAttributes(attributes) {
        return this.attachableDisplay.instanceBoundsForAttributes(this, attributes)
    }
}