import { defs, unitConverter } from './Utils';
import { SpaceForBoundsFromBoxes, FitForItemFromBoxes } from './MarginInsertion';

export const FitBounds = (item, frame) => {
    return {
        ...item,
        position: {
            x: item.position.x < 0
                ? 0
                : item.position.x + item.bounds.width > frame.x + frame.width
                    ? frame.x + frame.width - item.bounds.width
                    : item.position.x,
            y: item.position.y < 0
                ? 0
                : item.position.y + item.bounds.height > frame.y + frame.height
                    ? frame.y + frame.height - item.bounds.height
                    : item.position.y
        }
    }
}

export const Colliders = {
    "freeslot_2d": function(item, boxes, displayBounds, config) {
        const locationFrame = {
            x: this.position.x,
            y: this.position.y,
            width: this.bounds.width,
            height: this.bounds.height
        }

        var boundResult = null
        if (item == null) {
            boundResult = SpaceForBoundsFromBoxes(
                { x: 0, y: 0 },
                unitConverter( displayBounds(), { scale: { x: 1.0, y: 1.0 } }),
                { x: 1.0, y: 1.0 },
                boxes,
                locationFrame,
                {
                    ...config,
                    enforceFencing: this.enforceLocationFencing
                }
            )

            if (boundResult == null)
                item = { }
        }

        var updatedLocation = null
        if (boundResult == null) {
            // Initialize the insertion location
            const cScale = defs(item.scale, {
                x: 1.0,
                y: 1.0
            })
            var updatedLocation = {
                position: defs(item.position, {
                    x: 0,
                    y: 0,
                }),
                bounds: unitConverter(defs(item.bounds, displayBounds()), { scale: cScale }), // TODO: Pass in attached attributes
                scale: cScale,
            }
            if (this.enforceLocationFencing)
                updatedLocation = FitBounds(updatedLocation, locationFrame);
        }

        // Enforce location policy on item location

        if (this.enforceDisplayFencing == true &&
            boundResult == null) {
            boundResult = FitForItemFromBoxes(
                updatedLocation.position,
                updatedLocation.bounds,
                updatedLocation.scale,
                boxes,
                locationFrame,
                {
                    ...config,
                    enforceFencing: this.enforceLocationFencing
                },
                {
                    maxThreshold: 100000,
                    keepRatio: true
                })
        }

        if (boundResult != null) {
            updatedLocation = {
                ...updatedLocation,
                position: {
                    x: boundResult.x,
                    y: boundResult.y
                },
                bounds: {
                    width: boundResult.width,
                    height: boundResult.height
                },
                scale: boundResult.scale
            }
        }

        return updatedLocation;
    }
}