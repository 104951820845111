import DisplayInstance from './DisplayInstance';

export default class AttachableDisplay {
    internalId
    displayName
    displayType
    attributes
    attachmentSlots // Should keep in mind that some slots can have a dynamic list of items, eg a slideshow

    constructor(internalId, displayName, displayType) {
        if (this.constructor == AttachableDisplay) {
            throw new Error("Abstract class AttachableDisplay can't be instantiated.");
        }

        this.internalId = internalId;
        this.displayName = displayName;
        this.attributes = { };
        this.attachmentSlots = { };

        if (displayType == null) {
            this.displayType = DisplayInstance.prototype;
        } else {
            this.displayType = displayType;
        }
    }

    canAccept(attachment, slotName) { // Determines whether an item can be accepted in any position
        var destinations = null;
        if (typeof attachment == "string") {
            destinations = new Set([attachment]);
        } else if (typeof attachment == "set") {
            destinations = attachment;
        } else if (attachment.destinations && typeof destinations == "object") {
            destinations = new Set(Object.keys(attachment.destinations));
        } else {
            return null;
        }

        var slotKeys = new Set(Object.keys(this.attachmentSlots));
        if (slotName != null) {
            if (slotKeys.has(slotName)) {
                slotKeys = new Set([slotName]);
            } else {
                return false;
            }
        }

        for (let slotKey of slotKeys) {
            const commonKeys = new Set([
                ...this.attachmentSlots[slotKey].accepts]
                .filter(item => destinations.has(item)));
            if (commonKeys.size != 0) {
                return true;
            }
        }

        return false;
    }

    createDisplayInstance(id, instanceName) {
        let instance = new this.displayType.constructor(id, instanceName, this);

        if (this.instanceDestinations) {
            const attachableDestinationKeys = Object.keys(this.instanceDestinations);
            for (let key of attachableDestinationKeys) {
                instance.destinations[key] = [];
                for (let destination of this.instanceDestinations[key]) {
                    let attributes = {
                        destinationComponent: destination.bind(instance)
                    }
                    instance.destinations[key].push(attributes);
                }
            }
        }

        return instance;
    }

    handleInstanceSlotName(renderParams, slotName, slotAttachments) {
        throw new Error("Abstract function 'handleInstanceSlotName' needs to be implemented by inheritors");
    }

    handleInstanceRoot(rootAttributes, processComponents) {
        throw new Error("Abstract function 'handleInstanceRoot' needs to be implemented by inheritors");
    }

    serializableInfo() {
        throw new Error("Abstract function 'serialInfo' needs to be implemented by inheritors");
    }

    instanceBoundsForAttributes(displayInstance, attributes) {
        return { }
    }
}