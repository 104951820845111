import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App, { mainStore } from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";

import Amplify, { Auth } from 'aws-amplify';
import awsmobile from './aws-exports';

Amplify.configure(awsmobile)
Auth.configure(awsmobile)

ReactDOM.render(
    <Provider store={mainStore}>
        <App />
    </Provider>,
    document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
