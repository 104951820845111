import metaMaskProvider from '@metamask/detect-provider';

const startEthereumProvider = async () => {
    const provider = await metaMaskProvider();
    if (provider !== window.ethereum) {
        console.log("Couldn't start ethereum provider");
    }

    return provider;
}

export const setupMetaMask = async () => {
    const provider = await startEthereumProvider();
    if (provider) {
        startEthereumProvider();
    } else {
        console.log("MetaMask not installed");
    }
}

export const findMetaMaskAccounts = async () => {
    if (window.ethereum) {
        const accounts = await window.ethereum.request({method: "eth_requestAccounts"})

        return accounts;
    } else {
        return [];
    }
}