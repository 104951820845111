import { defs, checkContainment } from './Utils';

export default class MarginInsertion {
    horizontalMargins
    verticalMargins

    configuration
    containmentCallback

    constructor(horizontal, vertical, configuration, callback) {
        this.horizontalMargins = horizontal.sort((a, b) => a - b);
        this.verticalMargins = vertical.sort((a, b) => a - b);

        this.configuration = configuration

        this.containmentCallback = callback
    }

    framesInRegion(bounds, region, wantsSmaller = false, wantsFirst = true) {
        const maxX = this.horizontalMargins[this.horizontalMargins.length - 1];
        const maxY = this.verticalMargins[this.verticalMargins.length - 1];

        const x_increments = region.startX < region.stopX;
        const y_increments = region.startY < region.stopY;

        var cy = region.startY;
        var regionEnd = false;

        var allFrames = new Array();

        while ((y_increments ? cy <= region.stopY : cy >= region.stopY) && !regionEnd) {
            var cx = region.startX;
            var cx_len = 1;
            var cy_len = 1;

            var lineEnd = false;

            while ((x_increments ? cx + cx_len <= region.stopX : cx - cx_len >= region.stopX) &&
                (y_increments ? cy + cy_len <= region.stopY : cy - cy_len >= region.stopY) &&
                !lineEnd && !regionEnd) {
                const left = this.horizontalMargins[x_increments ? cx : cx - cx_len];
                const width = this.horizontalMargins[x_increments ? cx + cx_len : cx] - left;

                const canExtendX = (() => x_increments ? cx + cx_len < region.stopX : cx - cx_len > region.stopX);
                const canExtendY = (() => y_increments ? cy + cy_len < region.stopY : cy - cy_len > region.stopY);
                const widthNotFit = bounds.width > width;
                if (!wantsSmaller && widthNotFit) {
                    cx_len += 1;
                    if (!canExtendX()) {
                        cx_len = 1;
                        lineEnd = true;
                    }
                    continue
                }

                const top = this.verticalMargins[y_increments ? cy : cy_len - cy_len];
                const height = this.verticalMargins[y_increments ? cy + cy_len : cy] - top;
                const heightNotFit = bounds.height > height;
                if (!wantsSmaller && heightNotFit) {
                    cy_len += 1;
                    if (!canExtendY()) {
                        cy_len = 1;
                        regionEnd = true;
                    }
                    continue
                }

                const currentFrame = {
                    x: left,
                    y: top,
                    width: wantsSmaller ? width : bounds.width,
                    height: wantsSmaller ? height : bounds.height,
                    scale: {
                        x: 1.0,
                        y: 1.0
                    }
                }

                const conflicts = this.containmentCallback(currentFrame, false).length != 0
                if (!conflicts) {
                    if (!this.configuration.enforceFencing ||
                        !(currentFrame.x < 0 ||
                         currentFrame.y < 0 ||
                         currentFrame.x + (currentFrame.width * currentFrame.scale.x) > maxX ||
                         currentFrame.y + (currentFrame.height * currentFrame.scale.y) > maxY)) {
                        allFrames.push(currentFrame);
                    }
                }

                if (wantsSmaller && !conflicts) {
                    if ((cx_len == cy_len || !canExtendY()) && canExtendX && widthNotFit) {
                        cx_len += 1;
                    } else if ((cx_len != cy_len || !canExtendX()) && canExtendY && heightNotFit) {
                        cy_len += 1;
                    } else {
                        lineEnd = true;
                    }
                } else if (wantsFirst && !conflicts) {
                    regionEnd = true;
                } else {
                    cx += x_increments ? cx_len : -cx_len;
                    cx_len = 1;
                    cy_len = 1;
                }
            }

            cy += y_increments ? 1 : -1;
        }

        return wantsFirst ? allFrames[0] : allFrames;
    }

    // Return a position, bounds and scale for the given bounds
    findSpaceForBounds(bounds) {
        return this.framesInRegion(bounds,
        {
            startY: 0,
            stopY: this.verticalMargins.length - 1,
            startX: 0,
            stopX: this.horizontalMargins.length - 1
        })
    }

    // Return an adjusted position, bounds and scale for given element that doesn't overlap other bounds
    findNextFit(position, bounds, scale, heuristic, options) {
        var currentFrames = new Array();

        var currentDistance = 1;

        const insertionFrame = {
            x: defs(position.x, position.left, 0),
            y: defs(position.y, position.top, 0),
            width: bounds.width * defs(scale.x, 1.0),
            height: bounds.height * defs(scale.y, 1.0),
            scale: scale
        }
        const containmentBoxes = this.containmentCallback(insertionFrame, true);

        if (containmentBoxes.length == 0) {
            return {
                x: insertionFrame.x,
                y: insertionFrame.y,
                width: bounds.width,
                height: bounds.height,
                scale: scale
            }
        }

        const margin_left = Math.min(this.horizontalMargins.findIndex( (margin) => margin == position.x ) + 1, this.horizontalMargins.length - 1)
        const margin_right = Math.max(this.horizontalMargins.findIndex( (margin) => margin == position.x + bounds.width ) - 1, 0)
        const margin_top = Math.min(this.verticalMargins.findIndex( (margin) => margin == position.y ) + 1, this.verticalMargins.length - 1)
        const margin_bottom = Math.max(this.verticalMargins.findIndex( (margin) => margin == position.y + bounds.height ) - 1, 0)

        var isEnd = false;
        while (!isEnd) {
            const regions = [{
                startY: 0,
                stopY: this.verticalMargins.length - 1,
                startX: margin_left,
                stopX: 0
            }, {
                startY: 0,
                stopY: this.verticalMargins.length - 1,
                startX: margin_right,
                stopX: this.horizontalMargins.length - 1
            }, {
                startY: margin_top,
                stopY: 0,
                startX: 0,
                stopX: this.horizontalMargins.length - 1
            }, {
                startY: margin_bottom,
                stopY: this.verticalMargins.length - 1,
                startX: 0,
                stopX: this.horizontalMargins.length - 1
            }]

            for (let region of regions) {
                const regionFrames = this.framesInRegion(bounds, region, true, false);

                if (regionFrames.length == 0)
                    continue;

                // Align frames to top, left, right or bottom and adjust the scale for each
                var adjustedFrames = new Array();
                regionFrames.forEach((frame) => {
                    var xRatio = Math.min(frame.width / bounds.width, 1.0);
                    var yRatio = Math.min(frame.height / bounds.height, 1.0);

                    if (xRatio != 1.0 || yRatio != 1.0) {
                        if (options.keepRatio == true) {
                            const ratio = Math.min(xRatio, yRatio);
                            xRatio = ratio;
                            yRatio = ratio;
                        }
                    }

                    // Left Frame
                    adjustedFrames.push({
                        x: frame.x,
                        y: position.y >= frame.y && position.y + (bounds.height * yRatio) < frame.y + frame.height ? position.y : frame.y,
                        width: bounds.width,
                        height: bounds.height,
                        scale: {
                            x: xRatio,
                            y: yRatio
                        }
                    })

                    // right frame
                    if (frame.width > bounds.width) { // Skip right insertion if frame width < bounds width because it will produce the same rect as left
                        adjustedFrames.push({
                            x: frame.x + frame.width - (bounds.width * xRatio),
                            y: position.y >= frame.y && position.y + (bounds.height * yRatio) < frame.y + frame.height ? position.y : frame.y,
                            width: bounds.width,
                            height: bounds.height,
                            scale: {
                                x: xRatio,
                                y: yRatio
                            }
                        })
                    }

                    // top frame
                    adjustedFrames.push({
                        x: position.x >= frame.x && position.x + (bounds.width * xRatio) < frame.x + frame.width ? position.x : frame.x,
                        y: frame.y,
                        width: bounds.width,
                        height: bounds.height,
                        scale: {
                            x: xRatio,
                            y: yRatio
                        }
                    })

                    // bottom frame
                    if (frame.height > bounds.height) { // Skip right insertion if frame height <  bounds height because it will produce the same rect as top
                        adjustedFrames.push({
                            x: position.x >= frame.x && position.x + (bounds.width * xRatio) < frame.x + frame.width ? position.x : frame.x,
                            y: frame.y + frame.height - (bounds.height * yRatio),
                            width: bounds.width,
                            height: bounds.height,
                            scale: {
                                x: xRatio,
                                y: yRatio
                            }
                        })
                    }
                })

                // Get Heuristic for each frame and chose the best one
                const heuristics = adjustedFrames.map((frame) => heuristic(insertionFrame, frame));
                const minValue = Math.min(...heuristics);
                const adjustedFrameIndex = heuristics.findIndex((value) => value == minValue);

                currentFrames.push({
                    heuristic: minValue,
                    frame: adjustedFrames[adjustedFrameIndex]
                })
            }

            // For now we are getting top heuristics from the top values first. We can tether out the heuristic adjusts more slowly by
            // putting a limit on how far each region extends

            const topHeuristic = currentFrames.sort((a, b) => a.heuristic - b.heuristic)[0];

            if (topHeuristic != null && (!options.hasOwnProperty("maxThreshold") || topHeuristic.heuristic < options.maxThreshold)) {
//                console.log("Adjust insertion frame from region:",
//                    currentFrames.findIndex((item) => item == topHeuristic),
//                    "(h: " + topHeuristic.heuristic + ")",
//                    "(frame: " + topHeuristic.frame + ")");
                return topHeuristic.frame;
            }

            isEnd = true;
        }

        return null;
    }

    // Insert new margins
    insertMargins(horizontal, vertical) {

    }
}

const getMargins = (boxes, areaBounds) => {
    var horizontalMargins = new Set([]);
    var verticalMargins = new Set([]);

    const areaLeft = defs(areaBounds.x, areaBounds.left, 0)
    const areaTop = defs(areaBounds.y, areaBounds.top, 0)
    const areaRight = defs(areaBounds.right, areaLeft + defs(areaBounds.width, 1))
    const areaBottom = defs(areaBounds.bottom, areaTop + defs(areaBounds.height, 1))

    horizontalMargins.add(areaLeft);
    horizontalMargins.add(areaRight);
    verticalMargins.add(areaTop);
    verticalMargins.add(areaBottom);

    for (let box of boxes) {
        const left = defs(box.x, box.left)
        const top = defs(box.y, box.top)

        if (left == null || top == null) continue;
        if (left < areaLeft) continue;
        if (top < areaTop) continue;

        const right = defs(box.right, left + defs(box.width, 1));
        const bottom = defs(box.bottom, top + defs(box.height, 1));

        if (right > areaRight) continue;
        if (bottom > areaBottom) continue;

        if (!horizontalMargins.has(left)) horizontalMargins.add(left);
        if (!horizontalMargins.has(right)) horizontalMargins.add(right);
        if (!verticalMargins.has(top)) verticalMargins.add(top);
        if (!verticalMargins.has(bottom)) verticalMargins.add(bottom);
    }

    return [horizontalMargins, verticalMargins];
}

// TODO: Reduce inputs to one location object
export const SpaceForBoundsFromBoxes = (position, bounds, scale, boxes, areaBounds, configuration) => {
    const currentFrame = {
        x: position.x,
        y: position.y,
        width: bounds.width,
        height: bounds.height,
        scale: scale
    }

    if (checkContainment(boxes)(currentFrame).length == 0) {
        return currentFrame
    }

    const [horizontalMargins, verticalMargins] = getMargins(boxes, areaBounds)
    const margins = new MarginInsertion([...horizontalMargins], [...verticalMargins], configuration, checkContainment(boxes));

    return margins.findSpaceForBounds(bounds);
}

export const FitForItemFromBoxes = (position, bounds, scale, boxes, areaBounds, configuration, options) => {
    const currentFrame = {
        x: position.x,
        y: position.y,
        width: bounds.width,
        height: bounds.height,
        scale: scale
    }

    if (checkContainment(boxes)(currentFrame).length == 0) {
        return currentFrame
    }

    const [horizontalMargins, verticalMargins] = getMargins(boxes, areaBounds);

    horizontalMargins.add(position.x);
    horizontalMargins.add(position.x + bounds.width);
    verticalMargins.add(position.y);
    verticalMargins.add(position.y + bounds.height);

    const margins = new MarginInsertion([...horizontalMargins], [...verticalMargins], configuration, checkContainment(boxes));

    const frameHeuristics = (baseFrame, newFrame) => {
        const movementX = Math.abs(baseFrame.x - newFrame.x);
        const movementY = Math.abs(baseFrame.y - newFrame.y);
        const bounding = Math.abs(baseFrame.width - newFrame.width) + Math.abs(baseFrame.height - baseFrame.height);
        const scaleX = Math.abs(baseFrame.scale.x - newFrame.scale.x) * 100;
        const scaleY = Math.abs(baseFrame.scale.y - newFrame.scale.y) * 100;

        return (movementX > 0 ? Math.pow(movementX, 2) : 0) +
               (movementY > 0 ? Math.pow(movementY, 2) : 0) +
               (bounding * 100) +
               (scaleX > 0 ? 10 * Math.pow(scaleX, 3) : 0) +
               (scaleY > 0 ? 10 * Math.pow(scaleY, 3) : 0);
    }

    return margins.findNextFit(position, bounds, scale, frameHeuristics, options || { });
}