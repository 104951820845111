import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { ShowcaseDetail, ShowcaseDetailShort } from './Creator/Styles';
import { deleteShowcase, getUserShowcases } from './GraphQL/Showcase';

function ShowcasesLayout() {
    const userReducer = useSelector( (state) => state.userReducer );
    const displayReducer = useSelector( (state) => state.displayReducer );
    const [currentShowcases, setShowcases] = useState({ });
    const { userId } = userReducer;
    const dispatch = useDispatch();

    const deleteListedShowcase = (id) => {
        dispatch(deleteShowcase(id));
        delete currentShowcases[id];
    }

    useEffect(() => {
        if (userId) {
            dispatch(getUserShowcases(userId));
            setShowcases(displayReducer.showcases);
        }
    }, [displayReducer.showcases, userId, dispatch]);

    return (
        <div>
            <div>
                <h2>Showcases</h2>
                {
                    Object.keys(currentShowcases).map((key) => {
                        return (
                            <div key={key}>
                                <Link style={ShowcaseDetail} to={"/showcases/creator/" + currentShowcases[key].id}><p>{currentShowcases[key].id}</p></Link>
                                <Link style={ShowcaseDetailShort} to={"/showcase/" + currentShowcases[key].id}><p>{currentShowcases[key].title}</p></Link>
                                <div style={ShowcaseDetailShort}><p>{currentShowcases[key].path}</p></div>
                                <div style={ShowcaseDetail}><p>{currentShowcases[key].updatedAt}</p></div>
                                <div style={{display: "inline-block"}}><button onClick={() => {
                                    deleteListedShowcase(key);
                                }}>X</button></div>
                            </div>
                        )
                    })
                }
            </div>
            <Link to={`/showcases/creator/`} key={userId}>
                <p>Create new showcase</p>
            </Link>
        </div>
    );
}

export default function Showcases() {
    
    return (
        <ShowcasesLayout />
    )
}