import { BareDisplay, SimpleFrameDisplay } from './Models/SimpleDisplayModels';
import ProfileDisplay from './Models/ProfileDisplay';
import PodiumDisplay from './Models/PodiumDisplay';
import Layered5Display from './Models/Layered5Display';
import { DO_RESOLUTION } from '../Utils/ConnectionResolution';
import API from '@aws-amplify/api';
import { userShowcaseQuery } from '../GraphQL/Showcase';
import SimpleShowcaseModel from '../Showcase/Models/SimpleShowcaseDisplay';

// Constants

// Flow - With nothing selected we will list all tokens to the right, and to the left of that we will
//  List all attachable items. This includes
//  1. All root display attachable items. These items describe any item they
//  can have attached as well as list any attributes they can accept. It lists the open slots of the items
//  and can create a new display instance.
//  2. All display instances that are not attached to the showcase tree
//  3. Any display instance that is attached to the showcase tree but can accept additional slot entries
// - When a token is selected first, filter is done on the display items to only show the one that can accept
//  selected item's destinations
// - When a display is selected first, the filter will filter in only items that can be attached to the display
// - When a display is shown, it is shown in preview mode with no attachments. Once a token is selected, that preview
//  updates to preview the item within the display. Multi slot displays should present the option to switch the preview slot
// - When a token is selected first, all the filtered in displays should also update there previews to add in the selected item
// Previews are like renders but they include pseudo attachments. To achieve that, a display item is called with preview
// attributes added to the render call. Thus, every attachable display will need to be preview aware.
//
// Our model will keep track of AttachableDisplays and DisplayInstances. We should get notified when a new attachable
//  display is added, or a new display instance is added.

export const ADD_SHOWCASE               = "ADD_SHOWCASE";
export const REMOVE_SHOWCASE            = "REMOVE_SHOWCASE";
export const ADD_ATTACHABLE_DISPLAY     = "ADD_ATTACHABLE_DISPLAY";
export const REMOVE_ATTACHABLE_DISPLAY  = "REMOVE_ATTACHABLE_DISPLAY";
export const ADD_DISPLAY_INSTANCE       = "ADD_DISPLAY_INSTANCE";
export const REMOVE_DISPLAY_INSTANCE    = "REMOVE_DISPLAY_INSTANCE";
export const UPDATE_DISPLAY_INSTANCE    = "UPDATE_DISPLAY_INSTANCE";
export const RESET_DISPLAY_INSTANCES    = "RESET_DISPLAY_INSTANCES";

export const DisplayReducer = (state = {
        showcases: { },
        showcaseModels: InstantiateShowcaseModels(),
        currentShowcase: null,
        attachableDisplays: InstantiateAttachableDisplays(),
        displayInstances: { }
    }, action) => {
    switch(action.type) {
        case ADD_SHOWCASE:
            return {
                ...state,
                showcases: {
                    ...state.showcases,
                    [action.payload.id]: action.payload
                }
            }
        case REMOVE_SHOWCASE:
            var showcases = state.showcases;
            delete showcases[action.payload.id];
            return {
                ...state,
                showcases: showcases
            }
        case ADD_ATTACHABLE_DISPLAY:
            return {
                ...state,
                attachableDisplays: {
                    ...state.attachableDisplays,
                    [action.payload.internalId]: action.payload
                }
            };
        case REMOVE_ATTACHABLE_DISPLAY:
            var displays = state.attachableDisplays;
            delete displays[action.payload.internalId]
            return {
                ...state,
                attachableDisplays: displays
            };
        case ADD_DISPLAY_INSTANCE:
            return {
                ...state,
                displayInstances: {
                    ...state.displayInstances,
                    [action.payload.instanceId]: action.payload
                }
            };
        case UPDATE_DISPLAY_INSTANCE: 
            return {
                ...state,
                displayInstances: {
                    ...state.displayInstances,
                    [action.payload.instanceId]: action.payload
                }
            }
        case REMOVE_DISPLAY_INSTANCE:
            var instances = state.attachableDisplays;
            delete instances[action.payload.instanceId]
            return {
                ...state,
                displayInstances: instances
            };
        case RESET_DISPLAY_INSTANCES:
            return {
                ...state,
                displayInstances: { }
            }
        default: return state;
    }
}

export function InstantiateShowcaseModels() {
    const map = { 
        "SimpleShowcaseModel": SimpleShowcaseModel.prototype.constructor
    };

    return map;
}

export function InstantiateAttachableDisplays() {
    // Bare Display
    let bareDisplay             = new BareDisplay("com.nuseums.display.bare", "Bare");
    let simpleFrameDisplay      = new SimpleFrameDisplay("com.nuseums.display.frame.simple", "Simple Frame");
    let profileDisplay          = new ProfileDisplay("com.nuseums.display.profile", "Profile");
    let podiumDisplay           = new PodiumDisplay("com.nuseums.display.podium", "Podium");
    let layered5Display         = new Layered5Display("com.nuseums.display.layered.5", "5 Layer");

    return {
        [bareDisplay.internalId]: bareDisplay,
        [simpleFrameDisplay.internalId]: simpleFrameDisplay,
        [profileDisplay.internalId]: profileDisplay,
        [podiumDisplay.internalId]: podiumDisplay,
        [layered5Display.internalId]: layered5Display
    };
}

export const InstantiateRemoteDisplayItem = (displayItem, gatherUnresolved) => (dispatch, getState) => {
    var attachableDisplay;
    if (displayItem.display_item_type) {
        const attachableDisplays = getState().displayReducer.attachableDisplays;
        if (attachableDisplays.hasOwnProperty(displayItem.display_item_type)) {
            attachableDisplay = attachableDisplays[displayItem.display_item_type];
        }
    }

    if (attachableDisplay) {
        // Create the display instance with the id, attachable display and attributes
        // TODO: Assign the attributes

        const displayInstance = attachableDisplay.createDisplayInstance(displayItem.instance_id, attachableDisplay.displayName);
        
        // Deserialize
        const serialized = JSON.parse(displayItem.serialized);
        const unresolved = displayInstance.deserialize(serialized, dispatch, getState);
        
        if (gatherUnresolved) 
            gatherUnresolved(unresolved);

        dispatch({
            type: ADD_DISPLAY_INSTANCE,
            payload: displayInstance
        });

        dispatch({
            type: DO_RESOLUTION,
        payload: {
                reference: {
                    type: "DISPLAY_INSTANCE",
                    id: displayInstance.instanceId
                },
                map: getState().displayReducer.displayInstances
            }
        })

        // Add child items
    }
}
