import { BrowserRouter, Link, Route, Switch } from 'react-router-dom';

import Main from './Main';
import Wallets from './Wallets/Wallets';
import Showcases from './Showcases';
import ShowcasesCreator from './Creator/ShowcasesCreator';
import WalletViewer from './WalletViewer';
import { ShowcaseLoaderView } from './Showcase/ShowcaseViewer';
import { useSelector } from 'react-redux';
import { NaviItemStyle } from './Styles';
import { UserLogin, UserLogout } from './UserLogin';
import { GUEST_USER } from './WalletsModel';
import { authorizedAccess } from './Unauthorized';

export function Router() {
    const userReducer = useSelector((state) => state.userReducer );
    const { userId } = userReducer;

    return (
        <div>
            <BrowserRouter his>
                <div>
                    {
                        (() => {
                            if (userId && userId != GUEST_USER) {
                                return (
                                    <div>
                                        <Link style={NaviItemStyle} to={"/"}>
                                            <p>Home</p>
                                        </Link>
                                        <Link style={NaviItemStyle} to={"/wallets"}>
                                            <p>Wallets</p>
                                        </Link>
                                        <Link style={NaviItemStyle} to={"/showcases"}>
                                            <p>Showcases</p>
                                        </Link>
                                        <Link style={NaviItemStyle} to={"/walletviewer"}>
                                            <p>Wallet Viewer</p>
                                        </Link>
                                        <Link style={NaviItemStyle} to={"/logout"}>
                                            <p>Log-out</p>
                                        </Link>
                                    </div>
                                )
                            } else {
                                return (
                                    <div>
                                        <Link style={NaviItemStyle} to={"/"}>
                                            <p>Home</p>
                                        </Link>
                                        <Link style={NaviItemStyle} to={"/walletviewer"}>
                                            <p>Wallet Viewer</p>
                                        </Link>
                                        <Link style={NaviItemStyle} to={"/login"}>
                                            <p>Log-in/Sign-up</p>
                                        </Link>
                                    </div>
                                )
                            }
                        })()
                    }
                </div>
                <div>
                    <Switch>
                        <Route exact path="/">
                            <Main />
                        </Route>
                        <Route exact path="/login">
                            <UserLogin />
                        </Route>
                        <Route exact path="/logout">
                            <UserLogout />
                        </Route>
                        <Route exact path="/wallets">
                            {  authorizedAccess(userId, <Wallets />) }
                        </Route>
                        <Route exact path="/showcases">
                            { authorizedAccess(userId, <Showcases />) }
                        </Route>
                        <Route exact path="/showcases/creator/">
                            { userId == null ? (<div></div>) : authorizedAccess(userId, <ShowcasesCreator />) }
                        </Route>
                        <Route path="/showcases/creator/:showcase_id">
                            { userId == null ? (<div></div>) : authorizedAccess(userId, <ShowcasesCreator />) }
                        </Route>
                        <Route path="/showcase/:showcase_id">
                            <ShowcaseLoaderView />
                        </Route>
                        <Route path="/walletviewer">
                            <WalletViewer />
                        </Route>
                    </Switch>
                </div>
            </BrowserRouter>
        </div>
    )
}