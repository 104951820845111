import React from 'react';

import AttachableDisplay from '../AttachableDisplay';
import { SimpleDisplayComponentFunctions } from './SimpleDisplayModels';

export default class ProfileDisplay extends AttachableDisplay {
    attachmentSlots = {
        "picture": {
            capacity: 1,
            accepts: ["DISPLAY_IMAGE"]
        }
    }

    instanceDestinations = {
        "MODEL_IMAGE": [
            function(attributes, asDestination) {
                return (this.renderInstance(attributes))
            }
        ]
    }

    instanceBoundsForAttributes(displayInstance, attributes) {
        return {
            width: 12,
            height: 12,
            unit: "inch"
        }
    }

    handleInstanceSlotName(renderParams, slotName, slotAttachments) {
        switch (slotName) {
            case "picture":
                const imgStyle = {
                    objectFit: "cover",
                    verticalAlign: "middle",
                    width: "100%",
                    height: "100%",
                }
                return slotAttachments.map((slotEntry, entryIndex) => {
                    var objectPosition = "50% 50%";
                    if (slotEntry.objectPosition) {
                        objectPosition = (slotEntry.objectPosition.left || "50") + "% " +
                            (slotEntry.objectPosition.top || "50") + "%";
                    }
                    const profileStyle = {
                        ...imgStyle,
                        objectPosition: objectPosition
                    }
                    return SimpleDisplayComponentFunctions.RenderImageWithStyle(slotEntry, profileStyle, entryIndex)
                });
            default:
                return null;
        }
    }

    handleInstanceRoot(rootAttributes, processComponents) {
        const width = rootAttributes.renderSize ? (rootAttributes.renderSize.width + "px") : null;
        const height = rootAttributes.renderSize ? (rootAttributes.renderSize.height + "px") : null;
        const imageContainerStyle = {
            borderRadius: "50%",
            overflow: "hidden",
            maxWidth: width,
            maxHeight: height,
            width: width,
            height: height,
            position: "absolute",
            display: "flex"
        }

        return (
            <div key={this.instanceId} style={imageContainerStyle}>
            {
                (processComponents(rootAttributes))
            }
            </div>
        )
    }

    serializableInfo() {
        return {
            displayType: this.internalId,
            variant: "default"
        }
    }
}