import React from 'react';

import AttachableDisplay from '../AttachableDisplay';
import { SimpleDisplayComponentFunctions } from './SimpleDisplayModels';

export default class PodiumDisplay extends AttachableDisplay {
    attachmentSlots = {
        "left": {
            capacity: 1,
            accepts: ["DISPLAY_IMAGE"]
        },
        "middle": {
            capacity: 1,
            accepts: ["DISPLAY_IMAGE", "MODEL_IMAGE"]
        },
        "right": {
            capacity: 1,
            accepts: ["DISPLAY_IMAGE"]
        }
    }

    instanceDestinations = {
        "MODEL_IMAGE": [
            function(attributes, asDestination) {
                return (this.renderInstance(attributes))
            }
        ]
    }

    instanceBoundsForAttributes(displayInstance, attributes) {
        return {
            width: 12,
            height: 12,
            unit: "inch"
        }
    }

    handleInstanceSlotName(renderParams, slotName, slotAttachments) {
        var containerStyle = {
            width: "33.3%",
            overflow: "hidden",
            position: "absolute",
            display: "flex"
        }
        const imgStyle = {
            objectFit: "cover",
            verticalAlign: "middle",
            width: "100%",
            height: "100%",
            position: "relative"
        }
        switch (slotName) {
            case "left":
                 containerStyle = {
                    ...containerStyle,
                    height: "75%",
                    left: "0%",
                    top: ((100 - 75) / 2) + "%"
                 }
                 break;
            case "middle":
                containerStyle = {
                    ...containerStyle,
                    height: "100%",
                    left: "33.4%",
                    top: "0%"
                }
                break;
            case "right":
                containerStyle = {
                    ...containerStyle,
                    height: "60%",
                    left: "66.7%",
                    top: ((100 - 60) / 2) + "%"
                }
                break;
            default:
                return null;
        }

        return slotAttachments.map((slotEntry, entryIndex) => {
            var objectPosition = "50% 50%";
            if (slotEntry.objectPosition) {
                objectPosition = (slotEntry.objectPosition.left || "50") + "% " +
                    (slotEntry.objectPosition.top || "50") + "%";
            }
            const badgeStyle = {
                ...imgStyle,
                objectPosition: objectPosition
            }

            return (
                <div key={slotName + "-" + entryIndex} style={containerStyle}>
                {
                    (() => {
                        if (slotEntry.asDestination == "MODEL_IMAGE") {
                            return (SimpleDisplayComponentFunctions.RenderChildInstance(slotEntry, renderParams, entryIndex));
                        } else {
                            return (SimpleDisplayComponentFunctions.RenderImageWithStyle(slotEntry, badgeStyle, entryIndex));
                        }
                    })()
                }
                </div>
            )
        });
    }

    handleInstanceRoot(rootAttributes, processComponents) {
        const width = rootAttributes.renderSize ? (rootAttributes.renderSize.width + "px") : null;
        const height = rootAttributes.renderSize ? (rootAttributes.renderSize.height + "px") : null;
        const imageContainerStyle = {
            overflow: "hidden",
            maxWidth: width,
            maxHeight: height,
            width: width,
            height: height,
            position: "absolute",
            display: "flex"
        }

        return (
            <div key={this.instanceId} style={imageContainerStyle}>
            {
                (processComponents(rootAttributes))
            }
            </div>
        )
    }

    serializableInfo() {
        return {
            displayType: this.internalId,
            variant: "default"
        }
    }
}