export const ADD_RESOLUTION     = "ADD_RESOLUTION";
export const DO_RESOLUTION      = "DO_RESOLUTION";
export const REMOVE_RESOLUTION  = "REMOVE_RESOLUTION";

export class ResolutionPlaceHolder {
    reference
    onResolve
}

const findResolution = (reference, state) => {
    if (state.resolutionsMap.hasOwnProperty(reference.type) &&
        state.resolutionsMap[reference.type].hasOwnProperty(reference.id)) {
        return state.resolutionsMap[reference.type][reference.id];
    }

    return null;
}

const doResolution = (map, placeholder) => {
    if (placeholder == null || !map.hasOwnProperty(placeholder.reference.id)) {
        return false;
    }

    const value = map[placeholder.reference.id];
    placeholder.onResolve(value);
    return true;
}

const doResolutionRemoval = (reference, state) => {
    var map = state.resolutionsMap;
    delete map[reference.type][reference.id];
    return {
        ...state,
        resolutionsMap: map
    };
}

export const ResolutionReducer = (state = {
    resolutionsMap: { }
}, action) => {
    switch(action.type) {
        case ADD_RESOLUTION:
            return {
                ...state,
                resolutionsMap: {
                    ...state.resolutionsMap,
                    [action.payload.reference.type] : {
                        ...state.resolutionsMap[action.payload.reference.type] || { },
                        [action.payload.reference.id]: action.payload
                    }
                }
            };
        case REMOVE_RESOLUTION:
            return doResolutionRemoval(action.payload.reference, state);
        case DO_RESOLUTION:
            const placeholder = findResolution(action.payload.reference, state);
            if (doResolution(action.payload.map, placeholder)) {
                return doResolutionRemoval(placeholder.reference, state);
            } else {
                return state;
            }
        default: 
            return state;
    }
}

export const resolve = (type, payload, reference, map) => (dispatch, getState) => {
    // Do state resolution
    if (type) {
        dispatch({
            type: type,
            payload: payload
        })
    }

    // Do Item reference resolution
    dispatch({
        type: DO_RESOLUTION,
        payload: {
            reference: reference,
            map: map
        }
    })

    // Remove the resolution
    dispatch({
        type: REMOVE_RESOLUTION,
        payload: { 
            reference: reference
        }
    });
}
