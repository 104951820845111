import { DisplayLocation, ShowcaseDisplayModel } from '../Showcase';
import { uuidv4 } from '../../WalletsModel';
import { Colliders } from '../../Utils/ColliderUtils';

export default class SimpleShowcaseModel extends ShowcaseDisplayModel {
    constructor() {
        super();

        this.id = uuidv4();
        this.reference = "SimpleShowcaseModel";

        this.setupLocations();
    }

    locationMap = { }

    setupLocations() {
        this.locationMap["canvas"] = {
            position: {
                x: 0,
                y: 0
            },
            bounds: {
                width: 1000,
                height: 1000
            },
            displayAttachmentSlots: {
                "picture": {
                    capacity: "inf",
                    accepts: ["MODEL_IMAGE"]
                }
            },
            enforceDisplayFencing: true,
            enforceLocationFencing: true,
        }
    }

    addLocation(id, name, reference, object) {
        const referenceObject = this.locationMap[reference] || { };
        const fillObject = {
            ...referenceObject,
            ...object
        }

        var location = new DisplayLocation(id, reference);
        location.name = name;
        for (let key of Object.keys(fillObject)) {
            location[key] = fillObject[key];
        }

        location.findLocationForItem = Colliders.freeslot_2d.bind(location);
        this.displayLocations.push(location);

        return location
    }

    defaultLocations() {
        const object = this.locationMap["canvas"]

        if (object) {
            this.addLocation(uuidv4(), "main", "canvas", object)
        }
    }

    render(parameters, locationAdapter) {
        return (
            <div key={this.id}>
                {
                    locationAdapter.renderLocations(parameters)
                }
            </div>
        )
    }
}