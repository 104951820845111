import React from 'react';

import { uuidv4 } from '../WalletsModel';
import { defs, resolveSerializedObject, unitConverter } from '../Utils/Utils';

export class DisplayLocation {
    id
    name
    reference
    displayAttachmentSlots // slotNames mapped to capacity and acceptable types

    position
    bounds

    enforceDisplayFencing // Ensures that display instances solely occupy their own bounding area
    enforceLocationFencing // Prevent the display instance isn't placed outside the location's bounds

    findLocationForItem(item, boxes, displayBounds) {
        // Implemented by adopter
    }

    constructor(id, reference) {
        this.id = id;
        this.reference = reference;
        this.displayAttachmentSlots = { };
    }

    render(attributes, entries) {
        const modelScale = attributes.scale || 1.0;
        const modelStyle = {
            ...(attributes.style || { }),
            position: "relative",
            width: (this.bounds.width || 0) + "px",
            height: (this.bounds.height || 0) + "px"
        }

        const displayInstanceRenderParams = { };
        const displayWrapperStyle = {
            position: "absolute"
        };

        const highlights = attributes.highlights;
        const previews = attributes.renderPreview;

        var previewMap = null;
        if (previews) {
            previewMap = { };
            Object.keys(previews).map((previewItemKey) => {
                const slotName = previews[previewItemKey].slotName;
                if (slotName) {
                    if (!previewMap.hasOwnProperty(slotName)) {
                        previewMap[slotName] = [];
                    }

                    previewMap[slotName].push(previewItemKey)
                }
            })
        }

        var slotEntryKeys = Object.keys(entries);
        if (previewMap) {
            Object.keys(previewMap).forEach((previewSlotName) => {
                if (!entries.hasOwnProperty(previewSlotName)) {
                    slotEntryKeys.push(previewSlotName);
                }
            })
        }

        return (
            <div key={this.id} style={modelStyle}>
            {
                // Render each display and wrap them around an absolute display
                slotEntryKeys.map((slotName) => {
                    var entryKeys = entries.hasOwnProperty(slotName) ? Object.keys(entries[slotName]) : [];
                    if (previewMap) {
                        const list = previewMap[slotName] || [];
                        list.forEach((previewKey) => {
                            if (!entries.hasOwnProperty(previewKey)) {
                                entryKeys.push(previewKey);
                            }
                        })
                    }

                    return (entryKeys.map((displayIndex) => {
                        var display, showcaseLocation, displayId = null;
                        if (entries.hasOwnProperty(slotName) &&
                            entries[slotName].hasOwnProperty(displayIndex) &&
                            entries[slotName][displayIndex] != null) {
                            display = entries[slotName][displayIndex].display;
                            showcaseLocation = entries[slotName][displayIndex].showcaseLocation;
                            displayId = display.instanceId;
                        } else {
                            displayId = displayIndex;
                        }

                        if (previews) {
                            if (previews.hasOwnProperty(displayId)) {
                                showcaseLocation = previews[displayId].showcaseLocation || showcaseLocation;
                                display = previews[displayId].display || display
                            }
                        }

                        if (display == null) {
                            return null;
                        }

                        const renderParameters = {
                            ...displayInstanceRenderParams,
                            // TODO: Add width parameters based on render scale

                            // TODO: Grab attributes for each instance from the attributes object
                        }

                        const convertedLocation = unitConverter(showcaseLocation.position, { scale: modelScale })
                        const convertedBounds = unitConverter(showcaseLocation.bounds, { scale: modelScale });

                        const wrapperStyle = {
                            ...displayWrapperStyle,
                            left: (convertedLocation.x || 0) + "px",
                            top: (convertedLocation.y || 0) + "px",
                            width: (convertedBounds.width || 0) + "px",
                            height: (convertedBounds.height || 0) + "px"
                        }

                        renderParameters.renderSize = {
                            width: convertedBounds.width || 0,
                            height: convertedBounds.height || 0
                        }

                        return (
                            <div key={slotName + displayId} style={wrapperStyle}>
                            {
                                display.renderInstance(renderParameters)
                            }
                            </div>
                        )
                    }))
                })
            }
            {
                (() => {
                    if (highlights) {
                        return highlights.map((bounds) => {
                            var previewLocation = null
                            if (previews.hasOwnProperty(bounds.id)) {
                                previewLocation = previews[bounds.id].showcaseLocation
                            }
                            const style = {
                                left: (previewLocation == null ? bounds.x : previewLocation.position.x) + "px",
                                top: (previewLocation == null ? bounds.y : previewLocation.position.y) + "px",
                                width: (previewLocation == null ? bounds.width : previewLocation.bounds.width) + "px",
                                height: (previewLocation == null ? bounds.height : previewLocation.bounds.height) + "px",
                                position: "absolute",
                                outlineColor: "red",
                                outlineStyle: "solid",
                                outlineWidth: "2px",
                                zIndex: 100000
                            }
                            return (<div key={bounds.id + "_outline"} style={style}></div>)
                        })
                    }
                })()
            }
            {
                (() => {
                    if (previews) {
                        return Object.keys(previews).map((key) => {
                            const expectedLocation = previews[key].expectedLocation
                            if (expectedLocation) {
                                const style = {
                                    left: expectedLocation.position.x + "px",
                                    top: expectedLocation.position.y + "px",
                                    width: expectedLocation.bounds.width + "px",
                                    height: expectedLocation.bounds.height + "px",
                                    position: "absolute",
                                    outlineColor: "green",
                                    outlineStyle: "dotted",
                                    outlineWidth: "4px",
                                    zIndex: 110000
                                }
                                return (<div key={key + "_expected"} style={style}></div>)
                            }
                            return null
                        })
                    }
                })()
            }
            </div>
        )
    }
}

export class LocationAdapter {
    displayLocations

    displayInstanceMap // Location Id - slotName - Instance Id -> References display instances in the displays repository
    displayInstanceLocationMap // Instance Id - Location Id

    getLocationBounds(locations) {

    } // Returns a list of 3d bounding boxes for the location

    getOccupiedRegions(locations) { // Return regions that cannot accept item placement
        var boxes = new Array();

        Object.keys(locations).forEach((locationKey) => {
            const locationMap = this.displayInstanceMap[locationKey] || { };
            const locationExclusions = locations[locationKey].exclusionSet ||
                new Set([...(locations[locationKey].exclude || [])]);

            Object.keys(locationMap).forEach((slotName) => {
                const slotMap = locationMap[slotName] || [];

                slotMap.forEach((entry) => {
                    if (entry == null)
                        return;

                    const { showcaseLocation, display } = entry;

                    if (locationExclusions.has(display.instanceId))
                        return;

                    boxes.push({
                        id: display.instanceId,
                        x: showcaseLocation.position.x,
                        y: showcaseLocation.position.y,
                        width: showcaseLocation.bounds.width * defs(showcaseLocation.scale.x, 1.0),
                        height: showcaseLocation.bounds.height * defs(showcaseLocation.scale.y, 1.0),
                    });
                });
            });
        });

        return boxes;
    }

    // TODO: Location should have its own attributes. For instance a location can be a clone of another but with a location offset
    constructor(locations) {
        this.displayLocations = locations;
        this.displayInstanceMap = { }
        this.displayInstanceLocationMap = { };
    }

    findLocation(id) {
        const locationIndex = this.displayLocations.findIndex((location) => location.id == id);

        if (locationIndex >= 0)
            return this.displayLocations[locationIndex];

        return null;
    }

    itemInfo(displayInstance) {
        const info = this.displayInstanceLocationMap[displayInstance.instanceId];
        const location = this.findLocation(info.locationId);

        if (info == null || location == null) {
            return { }
        }

        // [showcaseLocation, displayLocation, slotName, slotIndex, destination];

        const slotEntries = this.displayInstanceMap[info.locationId][info.slotName] || [];
        const slotIndex = slotEntries.findIndex((entry) => entry != null ? entry.display.instanceId == displayInstance.instanceId : false);

        var result = { };
        info["location"] = location;
        info["slotName"] = info.slotName;
        info["slotIndex"] = slotIndex;
        info["showcaseLocation"] = slotEntries[slotIndex].showcaseLocation;
        // TODO: Add attributes once included

        return info;
    }

    _doAttach(displayInstance, showcaseLocation, displayLocation, slotName, slotIndex) {
        // Attach to showcase with showcase location and display instance

        if (!this.displayInstanceMap.hasOwnProperty(displayLocation)) {
            this.displayInstanceMap[displayLocation] = { }
        }

        if (!this.displayInstanceMap[displayLocation].hasOwnProperty(slotName)) {
            this.displayInstanceMap[displayLocation][slotName] = new Array((slotIndex || 0) + 1);
        } else if (slotIndex === null || slotIndex >= this.displayInstanceMap[displayLocation][slotName].length) {
            this.displayInstanceMap[displayLocation][slotName].push(null);
        }

        const atIndex = slotIndex || this.displayInstanceMap[displayLocation][slotName].length - 1;
        this.displayInstanceMap[displayLocation][slotName][atIndex] = {
            showcaseLocation: showcaseLocation,
            display: displayInstance
        }
        this.displayInstanceLocationMap[displayInstance.instanceId] = {
            slotName: slotName,
            locationId: displayLocation
        }
    }

    _detachDisplayInstance(displayInstanceId, location, slotName, slotIndex) {
        if (displayInstanceId == null) {
            return false;
        }

        this.displayInstanceMap[location][slotName][slotIndex] = null;
        delete this.displayInstanceLocationMap[displayInstanceId];

        while (this.displayInstanceMap[location][slotName].length > 0 &&
            this.displayInstanceMap[location][slotName][this.displayInstanceMap[location][slotName].length - 1] == null) {
            this.displayInstanceMap[location][slotName].pop();
        }

        if (this.displayInstanceMap[location][slotName].length == 0) {
            delete this.displayInstanceMap[location][slotName];
        }

        if (Object.keys(this.displayInstanceMap[location]).length == 0) {
            delete this.displayInstanceMap[location];
        }

        return true;
    }

    attachDisplayInstance(displayInstance, showcaseLocation, displayLocation, slotName, slotIndex) {
        const location = this.findLocation(displayLocation);

        // Get bounding boxes
        const locationMap = this.displayInstanceMap[displayLocation] || { };

        if (location == null)
            return [false, null];

        const updatedLocation = location.findLocationForItem(showcaseLocation,
            this.getOccupiedRegions({ [location.id]: { exclude: [displayInstance.instanceId] } }),
            () => displayInstance.boundsForAttributes({ }),
            {
            });
        if (updatedLocation == null) {
            // Count not find a location for the item
            return [false, null];
        }

        this._doAttach(displayInstance, updatedLocation, displayLocation, slotName, slotIndex);

        return [true, updatedLocation];
    }

    updateDisplayInstance(displayInstance, showcaseLocation, displayLocation, slotName, slotIndex) {
        // Update display location if preset
        var useDisplayLocation = null;
        var useShowcaseLocation = null;
        var useSlotName = null;
        var useSlotIndex = null;
        var shouldRemove = false;
        var locationChange = false;

        const displayInstanceInfo = this.itemInfo(displayInstance);

        if (displayLocation != null &&
            displayInstanceInfo.location.Id != displayLocation) {
            const location = this.findLocation(displayLocation);

            if (location == null)
                return [false, null];

            useDisplayLocation = location;

            shouldRemove = true;
            locationChange = true;
        } else {
            useDisplayLocation = displayInstanceInfo.location;
        }

        // Update showcase location if different
        if (showcaseLocation) {
            const location = {
                position: showcaseLocation.position || displayInstanceInfo.showcaseLocation.position,
                bounds: showcaseLocation.bounds || displayInstanceInfo.showcaseLocation.bounds,
                scale: showcaseLocation.scale || displayInstanceInfo.showcaseLocation.scale,
                rotation: showcaseLocation.rotation || displayInstanceInfo.showcaseLocation.rotation
            }

            if (!locationEqual(location, showcaseLocation)) {
                //  Do move logic
                useShowcaseLocation = useDisplayLocation.findLocationForItem(location,
                    this.getOccupiedRegions({ [useDisplayLocation.id]: { exclude: [displayInstance.instanceId] } }),
                    () => displayInstance.boundsForAttributes({ }),
                    {
                    });
            }
        }

        // Update slotName if different
        if (slotName && displayInstanceInfo.slotName != slotName) {
            useSlotName = slotName;

            // Remove from location
            shouldRemove = true;
        } else if (slotName == null && !locationChange) {
            // If location not changed use same slotName
            useSlotName = displayInstanceInfo.slotName;
        }

        // Update slot index if different
        if (slotIndex && displayInstanceInfo.slotIndex != slotIndex) {
            useSlotIndex = slotIndex;

            // Remove from slotIndex
            shouldRemove = true;
        } else if (slotIndex == null && !locationChange) {
            // If location not changed use same slotIndex

            useSlotIndex = displayInstanceInfo.slotIndex;
        }

        if (shouldRemove) {
            this._detachDisplayInstance(
                displayInstance.instanceId,
                displayInstanceInfo.location.id,
                displayInstanceInfo.slotName,
                displayInstanceInfo.slotIndex);

            // Reattach if relocated
            const [canAccept, onSlot] = this.canAccept(displayInstance, displayLocation, useSlotName, useSlotIndex);
            if (canAccept) {
                this._doAttach(displayInstance, useShowcaseLocation, useDisplayLocation.id, onSlot, useSlotIndex);
            }
        } else {
            // Update other attributes

            if (useShowcaseLocation) {
                this.displayInstanceMap[displayInstanceInfo.location.id][displayInstanceInfo.slotName][displayInstanceInfo.slotIndex].showcaseLocation = useShowcaseLocation;
            }
        }

        return [true, useShowcaseLocation];
    }

    detachDisplayInstance(displayInstance) {
        const displayInstanceInfo = this.itemInfo(displayInstance);

        if (displayInstanceInfo == null)
            return false;

        if (this._detachDisplayInstance(
            displayInstance.instanceId,
            displayInstanceInfo.location.id,
            displayInstanceInfo.slotName,
            displayInstanceInfo.slotIndex)) {
            displayInstance.displayParent = null;
            return true;
        }

        return false;
    }

    renderLocations(renderParameters) {
        const renderLocations = renderParameters.locations || [renderParameters.location];

        const commonAttributes = renderParameters.commonAttributes || { };

        return (
            renderLocations.map((locationProps) => {
                var locationIndex = null;

                if (locationProps.hasOwnProperty("index")) {
                    locationIndex = locationProps.index;
                } else if (locationProps.hasOwnProperty("id")) {
                    locationIndex = this.displayLocations.findIndex((location) => location.id = locationProps.id)
                }

                if (locationIndex === null || locationIndex == -1)
                    return (null);

                const location = this.displayLocations[locationIndex]

                const attributes = {
                    ...commonAttributes,
                    ...(locationProps.attributes || { }),
                    // TODO: Insert location attributes to be stored on this object
                }

                return location.render(attributes, this.displayInstanceMap[location.id] || { });
            })
        )
    }
}

export class ShowcaseDisplayModel {
    model // Any object, will be used for rendering
    displayReferenceMap // Id - Attachable Displays
    displayLocations // List of locations

    constructor() {
        this.model = null;
        this.displayReferenceMap = { };
        this.displayLocations = new Array();
    }

    addLocation(id, name, reference, object) { }

    defaultLocations() { }

    render(parameters, locationAdapter) { return (null) }
}

const locationEqual = (first, second) => {
    const checkValues = (key) => {
        if (first[key] && second[key]) {
            return Object.keys(first[key]).filter((prop) => first[key][prop] != second[key][prop]).length == 0
        }

        return false;
    }

    return (
        checkValues("position") &&
        checkValues("bounds") &&
        checkValues("scale") &&
        checkValues("rotation")
    )
}

export class ShowcaseLocation {
    position // x, y, optional z
    bounds // width, height optional depth
    scale // x, y, optional z
    rotation // x, y, optional z
    is3D = false

    equal(other) {
        return locationEqual(this, other)
    }
}

export class Showcase {
    id
    title
    path
    display // Display model instance that contains location and model references
    locationAdapter

    constructor(id, display) {
        this.id = id || uuidv4();
        this.setDisplay(display);
    }

    setDisplay(display) {
        this.display = display;
        if (display !== null) {
            this.locationAdapter = new LocationAdapter(display.displayLocations);
        } else {
            this.locationAdapter = new LocationAdapter([]);
        }
    }

    canAccept(displayInstance, location, slotName, slotIndex) {
        // Ensure location exists (location id)
        const displayLocation = this.locationAdapter.findLocation(location);

        if (displayLocation === null)
            return false;

        // Ensure location slot exists
        if (slotName != null && !displayLocation.displayAttachmentSlots.hasOwnProperty(slotName))
            return false;

        // Ensure location capacity can accept new entry
        var checkSlots = null;
        if (slotName == null) {
            checkSlots = Object.keys(displayLocation.displayAttachmentSlots);
        } else {
            checkSlots = [slotName];
        }

        for (let slot of checkSlots) {
            const slotInfo = displayLocation.displayAttachmentSlots[slot];

            // Check instance destination

            if (displayInstance.destinations == null)
                continue;

            const instanceDestinations = new Set([...Object.keys(displayInstance.destinations)]);
            const commonKeys = new Set([
                ...slotInfo.accepts]
                .filter(item => instanceDestinations.has(item)));
            if (commonKeys.size == 0) {
                continue;
            }

            if (slotInfo.capacity == "inf")
                return [true, slot];

            const capacity = slotInfo.capacity || 0;
            const list = ((this.locationAdapter.displayInstanceMap[displayLocation] || {})[slotName] || []);
            if (list.length < capacity)
                return [true, slot];
        }

        return false;
    }

    attachDisplayInstance(displayInstance, showcaseLocation, displayLocation, slotName, slotIndex) {
        const [canAccept, onSlot] = this.canAccept(displayInstance, displayLocation, slotName, slotIndex);
        if (!canAccept) {
            return [false, { }];
        }

        const result = this.locationAdapter.attachDisplayInstance(displayInstance, showcaseLocation, displayLocation, onSlot, slotIndex);

        if (result[0]) {
            displayInstance.displayParent = this;
        }

        return result; // Also return the updated showcase location
    }

    updateDisplayInstance(displayInstance, showcaseLocation, displayLocation, slotName, slotIndex) {
        if (displayLocation) {
            const [canAccept, onSlot] = this.canAccept(displayInstance, displayLocation, slotName, slotIndex);
            if (!canAccept) {
                return [false, { }];
            }
        }

        const result = this.locationAdapter.updateDisplayInstance(displayInstance, showcaseLocation, displayLocation, slotName, slotIndex);

        if (result[0]) {
            displayInstance.displayParent = this;
        }

        return result; // Also return the updated showcase location
    }

    displayInstancesInfo(displayInstance) {
        return this.locationAdapter.itemInfo(displayInstance);
    }

    detachDisplayInstance(displayInstance) {
        return this.locationAdapter.detachDisplayInstance(displayInstance);
    }

    defaultRenderParameters() {
        var params = {
            locations: this.locationAdapter.displayLocations.map((location) => { return { id: location.id }})
        }
        
        return params;
    }

    render(renderParameters) {
        if (this.display != null) {
            return (
                this.display.render(renderParameters, this.locationAdapter)
            )
        } else {
            return (null);
        }
    }

    serialize() {
        var obj = {
            locations: []
        }
        
        for (let location of this.locationAdapter.displayLocations) {
            // Save each location
            var loc = { };
            loc.id = location.id;
            loc.type = location.reference;
            loc.name = location.name;
            loc.position = location.position
            loc.bounds = location.bounds

            // Save the attachment
            if (this.locationAdapter.displayInstanceMap.hasOwnProperty(location.id)) {
                var attachments = { }

                const slotKeys = Object.keys(this.locationAdapter.displayInstanceMap[location.id]);
                for (let slotname of slotKeys) {
                    const slotInfo = this.locationAdapter.displayInstanceMap[location.id][slotname];
                    attachments[slotname] = [];

                    // Attach the display item info
                    for (let slotItem of slotInfo) {
                        if (slotItem) {
                            const info = { };
                            info.displayItem = slotItem.display.instanceId;
                            info.location = slotItem.showcaseLocation;
                            // TODO: Add attributes on item if available
                            attachments[slotname].push(info);
                        } else {
                            attachments[slotname].push("NULL");
                        }
                    }
                }

                loc.attachments = attachments;
            }

            obj.locations.push(loc)
        }

        return obj;
    }

    deserialize(serialized, model, dispatch, getState) {
        var useNewModel = null;
        if (this.display == null) {
            const modelConstructor = getState().displayReducer.showcaseModels[model];
        
            if (modelConstructor) {
                useNewModel = new modelConstructor();
            }
        }
        
        const displayModel = useNewModel || this.display
        
        if (serialized.locations && displayModel) {
            for (let location of serialized.locations) {               
                if (this.locationAdapter.displayLocations.findIndex((loc) => loc.id == location.id) >= 0)
                    continue

                displayModel.addLocation(location.id, location.name, location.type, {
                    position: location.position,
                    bounds: location.bounds
                });

                if (location.attachments) {
                    for (let slotname of Object.keys(location.attachments)) {
                        const list = location.attachments[slotname] || []
                        list.forEach((item, index) => {
                            if (item == "NULL") {
                                return;
                            }
                            // TODO: Deserialize to ShowcaseLocation
                            const showcaseLocation = item.location;
                            // TODO: Attach attributes onces found
                            const [resolved] = resolveSerializedObject({
                                id: item.displayItem,
                                type: "DISPLAY_INSTANCE"
                            }, dispatch, getState, (resolvedDisplayInstance) => {
                                this.locationAdapter._doAttach(resolvedDisplayInstance, showcaseLocation, location.id, slotname, index);
                                resolvedDisplayInstance.displayParent = this;
                            })

                            if (!resolved) {
                                // TODO: Add a placeholder display instance here
                            }
                        })
                    }
                }
            }
        }

        if (useNewModel != null) {
            this.setDisplay(useNewModel)
        }
    }
}