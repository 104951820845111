import React from 'react';
import { AmplifyAuthenticator, AmplifySignUp } from '@aws-amplify/ui-react';
import { Auth, Hub } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { GUEST_USER, signInUser,  signOutUser } from './WalletsModel';
import { useHistory } from 'react-router';

export function LoginAuthenticatedUser({redirectTo, history}) {
    const dispatch = useDispatch();
    const userReducer = useSelector((state) => state.userReducer );
    const { userId } = userReducer;

    React.useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(user => {
                if (user.username != userId) {
                    dispatch(signInUser(user.username));
                }
            })
            .catch(() => {
                dispatch(signInUser(GUEST_USER));
            });

        Hub.listen('auth', data => {
            switch (data.payload.event) {
                case 'signIn':
                    dispatch(signInUser(data.payload.data.username));
                    if (history && redirectTo) {
                        history.push(redirectTo)
                    }
                    break;
                case 'signOut':
                    dispatch(signOutUser());
                    break;
                default:
                    break;
            }
        });
    }, [dispatch]);
}

export function LogoutAuthenticatedUser() {
    const dispatch = useDispatch();

    console.log("logging out");

    React.useEffect(() => {
        Auth.signOut()
        .then((data) => {
            dispatch(signOutUser())
        })
        .catch(reason => console.log("Error logging out:", reason));
    }, [dispatch]);
}

export function UserLogin({redirectTo}) {
    LoginAuthenticatedUser({redirectTo: redirectTo || "/", history: useHistory()});

    return (
        <AmplifyAuthenticator>
            <AmplifySignUp slot="sign-up"
                formFields={[
                    { type: "username" },
                    { type: "password" },
                    { type: "email" }
                ]}
            />
        </AmplifyAuthenticator>
    )
}

export function UserLogout() {
    LogoutAuthenticatedUser();

    return (
        <div><h2>Logged out user</h2></div>
    )
}