import React from 'react';
import { useSelector } from 'react-redux';
import { GUEST_USER } from './WalletsModel';

function MainLayout() {
    const userReducer = useSelector((state) => state.userReducer );
    const { userId } = userReducer;

    return (
        <div>
            <div>
            {
                <h2>Welcome {userId && userId != GUEST_USER ? userId : "guest"}</h2>
            }
            </div>
        </div>
    )
}

export default function Main() {
    return (
        <MainLayout />
    )
}