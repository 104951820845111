/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "Auth": {
        "region": "us-west-2",
        "userPoolId": "us-west-2_1qI5iDfW1",
        "userPoolWebClientId": "344jki2uj251993v2kncjsvpg0",
        "identityPoolId": "us-west-2:f0d0b0b2-7cdb-427d-9ec2-8dbd80a59d21",
        "mandatorySignIn": false
    },
    "aws_appsync_graphqlEndpoint": "https://hfmniblc75fizckgwoljjgx36m.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};

export default awsmobile;
