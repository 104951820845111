import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql';
import { API } from 'aws-amplify';

import { ADD_SHOWCASE, InstantiateRemoteDisplayItem, REMOVE_DISPLAY_INSTANCE, REMOVE_SHOWCASE, UPDATE_DISPLAY_INSTANCE } from '../Display/DisplayModels';
import { Showcase } from '../Showcase/Showcase';
import { DO_RESOLUTION, resolve } from '../Utils/ConnectionResolution';
import { serializedTypeObject } from '../Utils/Utils';
import { RESOLVE_TOKEN, tokenResolutionQuery } from '../WalletsModel';

export const displayItemQuery = `
    query displayItem($instanceId: ID!) {
        displayItem(instance_id: $instanceId) {
            attached_at
            attached_to
            attached_type
            attributes
            display_item_type
            display_item_variant
            display_item_version
            instance_id
            owner_id
            serialized
        }
    }
`

export const userDisplayItemsQuery = `
    query userDisplayItems($ownerId: ID!) {
        userDisplayItems(owner_id: $ownerId) {
            attached_to
            attached_at
            attached_type
            attributes
            display_item_type
            display_item_variant
            display_item_version
            instance_id
            owner_id
            serialized
        }
    }
`

export const displayItemChildrenQuery = `
    query displayItemChildren($instanceId: ID) {
        displayItemChildren(instance_id: $instanceId) {
            attached_at
            attached_to
            attached_type
            attachments
            display_item_type
            display_item_variant
            display_item_version
            instance_id
            owner_id
            serialized
        }
    }
`

export const showcaseDisplayItemsQuery = `
    query showcaseDisplayItems($showcaseId: ID) {
        showcaseDisplayItems(showcase_id: $showcaseId) {
            attached_at
            attached_to
            attached_type
            attributes
            display_item_type
            display_item_variant
            display_item_version
            instance_id
            owner_id
            serialized
        }
    }
`

export const createDisplayItemMutation = `
    mutation createDisplayItem($displayItem: DisplayItemModelInput) {
        createDisplayItem(input: $displayItem) {
            instance_id
            owner_id
            attached_to
            attached_at
        }
    }
`

export const updateDisplayItemMutation = `
    mutation updateDisplayItem($displayItem: DisplayItemModelInput) {
        updateDisplayItem(input: $displayItem) {
            instance_id
            owner_id
            attached_to
            attached_at
        }
    }
`

export const attachDisplayItemToMutation = `
    mutation attachDisplayItemTo($instanceId: ID!, $to: ID!, $type: String!, $detachIfAttached: Boolean) {
        attachDisplayItemTo(instance_id: $instanceId, to: $to, type: $type, detachIfAttached: $detachIfAttached) {
            attached_to
            attached_at
            attached_type
            instance_id
            owner_id
        }
    }
`

export const detachDisplayItemMutation = `
    mutation detachDisplayItem($id: ID!) {
        detachDisplayItem(id: $id) {
            attached_at
            attached_to
            attached_type
            instance_id
            owner_id
        }
    } 
`

export const deleteDisplayItemMutation = `
    mutation deleteDisplayItem($instanceId: ID!) {
        deleteDisplayItem(instance_id: $instanceId)
    }
`

export const showcaseQuery = `
    query showcaseQuery($showcaseId: ID!) {
        showcase(showcase_id: $showcaseId) {
            showcase_id
            owner_id
            createdAt
            updatedAt
            title
            path
            layout_tree
            model
        }
    }
`

export const showcaseWithDisplayItemsQuery = `
    query showcaseQuery($showcaseId: ID!) {
        showcase(showcase_id: $showcaseId) {
            showcase_id
            owner_id
            createdAt
            updatedAt
            title
            path
            layout_tree
            model
        }

        showcaseDisplayItems(showcase_id: $showcaseId) {
            instance_id
            attached_to
        }
    }
`

export const userShowcasesQuery = `
    query userShowcasesQuery($ownerId: ID!) {
        userShowcases(owner_id: $ownerId) {
            showcase_id
            owner_id
            updatedAt
            title
            path
        }
    }
`

export const createShowcaseMutation = `
    mutation createShowcase($showcase: ShowcaseInput) {
        createShowcase(input: $showcase) {
            showcase_id
            owner_id
            createdAt
            updatedAt
        }
    }
`

export const updateShowcaseMutation = `
    mutation updateShowcase($showcase: ShowcaseInput) {
        updateShowcase(input: $showcase) {
            showcase_id
            owner_id
            title
            createdAt
            updatedAt
        }
    }
`

export const deleteShowcaseMutation = `
    mutation deleteShowcase($showcaseId: ID) {
        deleteShowcase(showcase_id: $showcaseId)
    }
`

const displayInstanceParent = (displayInstance) => {
    return serializedTypeObject(displayInstance.displayParent);
}

const displayInstanceProperties = (displayInstance, userId, getAttributes) => {

    const parent = displayInstanceParent(displayInstance)
    const info = displayInstance.attachableDisplay.serializableInfo();
    var result = {
        instance_id: displayInstance.instanceId,
        owner_id: userId,
        display_item_type: info.displayType,
        display_item_variant: info.variant,
        display_item_version: "1",
        attributes: JSON.stringify(getAttributes(displayInstance)),
        serialized: JSON.stringify(displayInstance.serialize()),
    }

    if (parent) {
        result = {
            ...result,
            attached_to: parent.id,
            attached_type: parent.type,
        }
    }

    return result;
}

export const appendDisplayItem = (displayInstance, getAttributes) => async (dispatch, getState) => {
    try {
        const userId = getState().userReducer.userId;
        const props = displayInstanceProperties(displayInstance, userId, getAttributes);
        const result = await API.graphql({
            query: createDisplayItemMutation,
            variables: { displayItem: props }
        })

        const displayItemProperties = result.data.createDisplayItem;
        updateDisplayInstanceProperties(displayInstance, displayItemProperties);

        dispatch({
            type: UPDATE_DISPLAY_INSTANCE,
            payload: displayInstance
        })
    } catch (error) {
        console.log("Error inserting new display instance:", error);
    }
}

const updateDisplayInstanceProperties = (displayInstance, properties) => {
    displayInstance.instanceId = properties.instance_id;
    /* TODO: Ensure displayParent and attachableDisplay match what is returned
        If not matched find a way to attach the matching values */
    /* TOOD: extract the attribute values and relay them to a attribute repository
        for the display instance */
}

export const updateDisplayItem = (displayInstance, getAttributes) => async (dispatch, getState) => {
    try {
        const userId = getState().userReducer.userId;
        const props = displayInstanceProperties(displayInstance, userId, getAttributes);
        const result = await API.graphql({
            query: updateDisplayItemMutation,
            variables: { displayItem: props }
        })

        const displayItemProperties = result.data.updateDisplayItem;
        updateDisplayInstanceProperties(displayInstance, displayItemProperties);

        dispatch({
            type: UPDATE_DISPLAY_INSTANCE,
            payload: displayInstance
        })
    } catch (error) {
        console.log("Error updating display instance:", error);
    }
}

export const attachDisplayItemTo = (displayInstance, getAttributes) => async (dispatch, getState) => {
    try {
        const userId = getState().userReducer.userId;
        const parent = displayInstanceParent(displayInstance, userId, getAttributes);
        const result = await API.graphql({
            query: attachDisplayItemToMutation,
            variables: {
                instanceId: displayInstance.instanceId,
                to: parent.id,
                type: parent.type,
                detachIfAttached: true
            }
        })

        const displayItemProperties = result.data.attachDisplayItemTo;
        updateDisplayInstanceProperties(displayInstance, displayItemProperties);

        dispatch({
            type: UPDATE_DISPLAY_INSTANCE,
            payload: displayInstance
        })
    } catch (error) {
        console.log("Error attaching display instance:", error);
    }
}

export const detachDisplayItem = (displayInstance) => async (dispatch, getState) => {
    try {
        const result = await API.graphql({
            query: detachDisplayItemMutation,
            variables: {
                id: displayInstance.instanceId,
            }
        })

        const displayItemProperties = result.data.detachDisplayItem;
        updateDisplayInstanceProperties(displayInstance, displayItemProperties);

        dispatch({
            type: UPDATE_DISPLAY_INSTANCE,
            payload: displayInstance
        })
    } catch (error) {
        console.log("Error detaching display instance:", error);
    }
}

export const deleteDisplayItem = (displayInstance) => async (dispatch, getState) => {
    try {
        await API.graphql({
            query: deleteDisplayItemMutation,
            variables: {
                id: displayInstance.instanceId
            }
        })

        dispatch({
            type: REMOVE_DISPLAY_INSTANCE,
            payload: displayInstance
        })
    } catch (error) {
        console.log("Error deleting display instance:", error);
    }
}

export const resolveAllDisplayItems = () => async (dispatch, getState) => {
    const userId = getState().userReducer.userId;

    console.log("Loading display items");

    API.graphql({
        query: userDisplayItemsQuery,
        variables: { ownerId: userId }
    }).then(displayItems => {
        if (displayItems.data.userDisplayItems != null) {
            displayItems.data.userDisplayItems.forEach(displayItem => {
                console.log("Add display item:", displayItem.instance_id);
                dispatch(InstantiateRemoteDisplayItem(displayItem));
            });
        }
    })
    .catch(err => console.log('User display item resolution error: ', err));
}

/// Showcases

const updateShowcase = (showcaseData, dispatch, getState) => {
    var showcase = getState().displayReducer.showcases[showcaseData.showcase_id];
    if (showcase == null) {
        var model = null;
        if (showcaseData.model != undefined) {
            const modelConstructor = getState().displayReducer.showcaseModels[showcaseData.model];

            if (modelConstructor) {
                model = new modelConstructor();
            }
        }
    
        showcase = new Showcase(showcaseData.showcase_id, model);
    }

    showcase.title = showcaseData.title
    showcase.path = showcaseData.path
    showcase.createdAt = showcaseData.createdAt;
    showcase.updatedAt = showcaseData.updatedAt;

    if (showcaseData.layout_tree && showcaseData.model) {
        const layoutTree = JSON.parse(showcaseData.layout_tree);
        showcase.deserialize(layoutTree, showcaseData.model, dispatch, getState);
    }

    dispatch({
        type: ADD_SHOWCASE,
        payload: showcase
    });

    dispatch({
        type: DO_RESOLUTION,
        payload: {
            reference: {
                type: "SHOWCASE",
                id: showcase.id
            },
            map: getState().displayReducer.showcases
        }
    })
}

const resolveShowcaseResources = (resources, userFlag) => async (dispatch, getState) => {
    var resourcesLeft = [];

    const updateResourcesLeft = (obj) => {
        if (!obj) {
            return;
        }

        if (obj.displayItems) {
            resourcesLeft.push(...obj.displayItems.map((item) => {
                return { 
                    id: item.instance_id,
                    type: "display"
                }
            }))
        }

        if (obj.tokens) {
            resourcesLeft.push(...obj.tokens.map((item) => {
                return {
                    id: item.id,
                    type: "token"
                }
            }))
        }
    }

    updateResourcesLeft(resources);

    while (resourcesLeft.length != 0) {
        const resource = resourcesLeft[0];
        resourcesLeft = resourcesLeft.splice(1);

        switch (resource.type) {
            case "display":
                const item = await API.graphql({
                    query: displayItemQuery,
                    variables: { instanceId: resource.id },
                    authMode: userFlag ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS : GRAPHQL_AUTH_MODE.AWS_IAM
                });
                const displayItem = item.data.displayItem
                if (displayItem != null) {
                    console.log("Add display item:", displayItem.instance_id);
                    var unresolved = [];
                    dispatch(InstantiateRemoteDisplayItem(displayItem, (currentList) => unresolved = currentList));
                    dispatch(resolveShowcaseResources({
                        displayItems: unresolved.filter((item) => item.type == "DISPLAY_INSTSANCE"),
                        tokens: unresolved.filter((item) => item.type == "WALLET_TOKEN")
                    }));
                } else {
                    console.log('Display Item resolution error: ', item.data.error);
                }
                break;
            case "token":
                // TODO: Can do multiple token resolutions
                const requestMap = [
                    {
                        token_id: resource.id
                    }
                ];
        
                API.graphql({
                    query: tokenResolutionQuery,
                    variables: { tokens: requestMap },
                    authMode: userFlag ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS : GRAPHQL_AUTH_MODE.AWS_IAM
                }).then((resolutionData) => {
                    if (resolutionData.data.resolveTokens.items != null) {
                        for (let resolution of resolutionData.data.resolveTokens.items) {
                            dispatch(
                                resolve(
                                    RESOLVE_TOKEN, 
                                    { 
                                        resolution 
                                    }, 
                                    {
                                        type: "WALLET_TOKEN",
                                        id: resolution.token_id
                                    },
                                    getState().walletReducer.repository.tokens
                                )
                            );
                        }
                    } else {
                        console.log('Token resolution error: ', resolutionData.data.error);
                    }
                })
                break;
            default: break;
        }
    }
}

export const getUserShowcases = (user) => async (dispatch, getState) => {
    try {
        await API.graphql({
            query: userShowcasesQuery,
            variables: { ownerId: user }
        }).then(userShowcaseData => {
            if (userShowcaseData.data.userShowcases) {
                userShowcaseData.data.userShowcases.forEach(showcaseData => {
                    updateShowcase(showcaseData, dispatch, getState);    
                })
            }
        })
    } catch (error) {
        console.log("Error getting user showcases:", error);
    }
}

export const getShowcase = (id) => async (dispatch, getState) => {
    try {
        const showcaseData = await API.graphql({
            query: showcaseQuery,
            variables: { showcaseId: id }
        });
        if (showcaseData.data.showcase) {
            updateShowcase(showcaseData.data.showcase, dispatch, getState);                
        }
    } catch (error) {
        console.log("Error getting user showcase:", error);
    }
}

export const getShowcaseAndResources = (id, userFlag) => async (dispatch, getState) => {
    try {
        const showcaseData = await API.graphql({
            query: showcaseWithDisplayItemsQuery,
            variables: { showcaseId: id },
            authMode: userFlag ? GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS : GRAPHQL_AUTH_MODE.AWS_IAM
        });
        
        if (showcaseData.data.showcase) {
            updateShowcase(showcaseData.data.showcase, dispatch, getState);                
        }

        if (showcaseData.data.showcaseDisplayItems) {
            dispatch(resolveShowcaseResources({
                displayItems: showcaseData.data.showcaseDisplayItems
            },
            userFlag));
        }
    } catch (error) {
        console.log("Error getting user showcases:", error);
    }
}

export const saveShowcase = (showcase) => async (dispatch, getState) => {
    try {
        const userId = getState().userReducer.userId;
        const modelStr = showcase.display.reference;
        const layoutTree = showcase.serialize();

        await API.graphql({
            query: showcase.isRemote == true ? updateShowcaseMutation : createShowcaseMutation,
            variables: {
                showcase: {
                    showcase_id: showcase.id,
                    owner_id: userId,
                    model: modelStr,
                    title: showcase.title,
                    path: showcase.path,
                    layout_tree: JSON.stringify(layoutTree)
                }
            }
        }).then(showcaseData => {
            showcase.isRemote = true;
        })
    } catch (error) {
        console.log("Error saving showcase:", error);
    }
}

export const deleteShowcase = (showcaseId) => async (dispatch, getState) => {
    try {
        await API.graphql({
            query: deleteShowcaseMutation,
            variables: { showcaseId }
        }).then(deleteData => {
            dispatch({
                type: REMOVE_SHOWCASE,
                payload: showcaseId
            })
        })
    } catch (error) {
        console.log("Error deleting showcase:", error);
    }
}