import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql';
import { API } from 'aws-amplify';
import md5 from 'blueimp-md5';
import { DestinationComponents } from './Display/DestinationComponents';
import { DO_RESOLUTION } from './Utils/ConnectionResolution';

// GraphQL Queries

export function uuidv4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

const walletMutation = `
    mutation createWallet($wallet: CreateBlockChainWalletInput) {
        createWallet(input: $wallet) {
            wallet_id owner_id block_token inserted_date source
        }
    }
`

const walletUpdate = `
    mutation updateWallet($wallet: UpdateBlockChainWalletInput) {
        updateWallet(input: $wallet) {
            wallet_id owner_id block_token source
        }
    }
`

const walletDeletion = `
    mutation deleteWallet($wallet_id: ID!) {
        deleteWallet(wallet_id: $wallet_id)
    }
`

const walletQuery = `
    query userWallets($owner_id: ID!) {
        userWallets(owner_id: $owner_id) {
            wallet_id owner_id block_token inserted_date source
        }
    }
`

const walletInfoQuery = `
    query resolveWallets($input: [WalletResolutionInput], $operation: String!) {
        resolveWallets(input: $input, resOperation: $operation) {
            error
            items {
                wallet_id
                tokens {
                    token_id
                    block_token
                    name
                    symbol
                    attached_parent
                    contract_address
                }
                wallet_block_token
                wallet_resolved
            }
        }
    }
`
export const tokenResolutionQuery = `
    query resolveTokens($tokens: [TokenResolutionInput]) {
        resolveTokens(input: $tokens) {
            error
            items {
                attached_parent
                block_token
                contract_address
                is_resolved
                name
                symbol
                token_id
                token_uri
                token_uri_resolution {
                    key
                    value
                }
                resolved_token_meta {
                    attributes {
                        key
                        value
                    }
                    destination
                }
            }
        }
    }
`

export const WalletWithResolvedTokensQuery = `
    query resolveWallets($wallet_block_token: String!, $operation: String!, $page: Int, $limit: Int) {
        resolveWallets(input: [{
                wallet_block_token: $wallet_block_token,
                wallet_category: "ETH",
                reply_with_tokens: true,
                resolve_token_uri: true,
                include_token_categories: true,
                page: $page,
                limit: $limit
                }], resOperation: $operation) {
            error
            items {
                wallet_id
                page
                tokens {
                    token_id
                    block_token
                    is_resolved
                    name
                    symbol
                    attached_parent
                    contract_address
                    token_uri_resolution {
                        key
                        value
                    }
                    resolved_token_meta {
                        destination
                        attributes {
                            key
                            value
                        }
                    }
                }
                wallet_block_token
                wallet_resolved
            }
        }
    }
`

const walletSubscription = `
  subscription onCreateWallet {
    onCreateWallet {
      wallet_id owner_id block_token inserted_date
    }
  }
`

// Action Consts

export const APPEND_WALLET          = "APPEND_WALLET";
export const UPDATE_WALLET          = "UPDATE_WALLET";
export const DELETE_WALLET          = "DELETE_WALLET";
export const RESOLVE_WALLET         = "RESOLVE_WALLET";
export const RESOLVE_TOKEN          = "RESOLVE_TOKEN";
export const UPDATE_BATCH_CONTEXT   = "UPDATE_BATCH_CONTEXT";

export const SIGN_IN_USER   = "SIGN_IN_USER";
export const SIGN_OUT_USER  = "SIGN_OUT_USER";

export const GUEST_USER     = -1

export const WalletReducer = (state = { repository: null }, action) => {
    switch (action.type) {
        case SIGN_IN_USER: {
            const newRepo = new WalletRepository(action.payload.userId, action.payload.dispatch);
            newRepo.loadRemoteWallets(false);
            return {
                repository: newRepo
            };
        }
        case SIGN_OUT_USER: return {
            repository: null
        };
        case APPEND_WALLET: return {
            ...state,
            repository: {
                ...state.repository,
                wallets: {
                    ...state.repository.wallets,
                    [action.payload.wallet.id] : action.payload.wallet
                },
                walletTypeMap: {
                    ...state.repository.walletTypeMap,
                    [action.payload.wallet.source || ""] : 
                        new Set([...(state.repository.walletTypeMap
                            [action.payload.wallet.source || ""] || []),
                            action.payload.wallet.id])
                }
            }
        };
        case UPDATE_WALLET: return {
            ...state,
            repository: {
                ...state.repository,
                wallets: {
                    ...state.repository.wallets,
                    [action.payload.wallet.id] : action.payload.wallet
                },
                walletTypeMap: {
                    ...state.repository.walletTypeMap,
                    [action.payload.wallet.source || ""] : 
                        new Set([...(state.repository.walletTypeMap
                            [action.payload.wallet.source || ""] || []),
                            action.payload.wallet.id])
                }
            }
        };
        case DELETE_WALLET:
            const id = action.payload.walletId;
            let wallets = state.repository.wallets;

            if (wallets[id]) {
                let sourceMap = state.repository.walletTypeMap;
                var source = sourceMap[wallets[id].source || ""];
                source.delete(id);

                if (source.size == 0) {
                    delete[sourceMap[wallets[id].source || ""]];
                } else { 
                    sourceMap[wallets[id].source || ""] = source;
                }

                delete wallets[id];
                return {
                    ...state,
                    repository: {
                        ...state.repository,
                        wallets: wallets,
                        walletTypeMap: sourceMap
                    }
                };
            } else {
                return state
            }
        case RESOLVE_WALLET: {
            if (!action.payload.resolution.wallet_resolved)
                return {
                    ...state
                };

            var allTokens = state.repository.tokens;
            var wallet = state.repository.wallets[action.payload.resolution.wallet_id];
            if (wallet == null) {
                console.log("No matching wallet");
                return {
                    ...state
                };
            }

            action.payload.resolution.tokens.forEach(tokenResolution => {
                if (wallet.tokens[tokenResolution.block_token] == null) {
                    let token = new WalletToken(tokenResolution.block_token);
                    token.resolve(tokenResolution);
                    
                    allTokens[token.id] = token; 
                    wallet.insertToken(token, true);
                } else {
                    let token = wallet.tokens[tokenResolution.block_token];
                    token.resolve(tokenResolution);
                }
            });

            return {
                ...state,
                repository: {
                    ...state.repository,
                    tokens: allTokens,
                    wallets: {
                        ...state.repository.wallets,
                        [wallet.id] : wallet
                    }
                }
            };
        }
        case RESOLVE_TOKEN: {
            if (action.payload.resolution.token_id == null) {
                return state;
            }

            var allTokens = state.repository.tokens;
            var token = state.repository.tokens[action.payload.resolution.token_id];
            if (!token) {
                token = new WalletToken(action.payload.resolution.block_token);
            }

            token.resolve(action.payload.resolution);
            allTokens[token.id] = token;

            var updatedWallets = state.repository.wallets;
            Object.keys(updatedWallets).forEach((key) => {
                var wallet = updatedWallets[key];
                if (wallet.tokens.hasOwnProperty(token.id)) {
                    wallet.tokens[token.id] = token;
                    updatedWallets[key] = wallet;
                }
            });

            return {
                ...state,
                repository: {
                    ...state.repository,
                    tokens: allTokens,
                    wallets: updatedWallets
                }
            };
        }
        case UPDATE_BATCH_CONTEXT: {
            var context = action.payload.context;
            // Update wallet keys here because the previous update needs to go through the dispatch queue
            if (context != null && context.walletKeys == null) {
                context.walletKeys = Object.keys(state.repository.wallets)
            }

            return {
                ...state,
                repository: {
                    ...state.repository,
                    batchResolutionContext: context
                }
            };
        }
        default: return state;
    }
};

export const UserReducer = (state = { userId: null }, action) => {
     switch (action.type) {
        case SIGN_IN_USER: return {
            ...state,
            ...action.payload
        };
        case SIGN_OUT_USER: return {
            userId: GUEST_USER
        };
        default: return state;
     }
};

export class WalletToken {
    id
    resolved
    blockChainToken
    blockChainCategory
    tokenName
    tokenSymbol
    mediaTypes
    destinations = { }
    contractAddress
    uri
    attributes = { }

    constructor(token) {
        this.blockChainToken = token;
    }

    isAssigned() {
        return this.displayDestination == null;
    }

    setAttribute(name, value) {
        this.attributes[name] = value;
    }

    getAttribute(name) {
        return this.attributes[name];
    }

    hasAttribute(name) {
        return this.attributes[name] != null;
    }

    updateWithValues(values) {
        this.id = values.token_id;
        this.blockChainToken = values.block_token;
    }

    resolve(resolution) {
        if (resolution.token_id)
            this.id = resolution.token_id;

        if (resolution.is_resolved)
            this.resolved = resolution.is_resolved;

        if (resolution.block_token)
            this.blockChainToken = resolution.block_token;

        if (resolution.category)
            this.blockChainCategory = resolution.category;

        if (resolution.name)
            this.tokenName = resolution.name;

        if (resolution.symbol)
            this.tokenSymbol = resolution.symbol;

        if (resolution.contract_address)
            this.contractAddress = resolution.contract_address;

        if (resolution.token_uri)
            this.uri = resolution.token_uri;

        if (resolution.resolved_token_meta) {
            this.destinations = { };

            resolution.resolved_token_meta.forEach((display) => {
                const destination = display.destination;

                var attributes = { };
                if (display.attributes) {
                    display.attributes.forEach((attribute) => {
                        var value = JSON.parse(attribute.value);

                        // Trim quotes from strings
                        if (typeof value == "string")
                            value = value.slice(1, -1);

                        attributes[attribute.key] = value;
                    })
                }

                // Add the destination function for this destination type
                if (DestinationComponents.hasOwnProperty(destination)) {
                    attributes.destinationComponent = DestinationComponents[destination].bind(attributes);
                } else {
                    attributes.destinationComponent = function(attributes) { };
                }

                // Create a list for the current destination if null
                if (!this.destinations.hasOwnProperty(destination)) {
                    this.destinations[destination] = [];
                }

                this.destinations[destination].push(attributes);
            });
        }

        if (!this.id && this.contractAddress && this.blockChainToken) {
            var hash = md5(this.contractAddress);
            hash = md5(hash, this.blockChainToken);
            this.id = hash; 
        }
    }

    componentForDestination(destination, attributes, index) {
        if (this.destinations[destination]) {
            return this.destinations[destination][index || 0].destinationComponent(attributes, destination);
        }

        return null;
    }

    componentForDestinationOrDefault(destination, attributes, index) {
        var component = this.componentForDestination(destination, attributes);

        if (component == null && destination != "DISPLAY_IMAGE") {
            component = this.componentForDestination("DISPLAY_IMAGE", attributes);
        }

        return component;
    }
}

export class WalletModel {
    id
    blockChainToken
    blockChainCategory
    userId
    insertedDate
    location // Remote or Local
    tokens

    #tokensMap

    constructor(id) {
        this.id = id;
        this.tokens = { };
        this.#tokensMap = { };
    }

    insertToken(token, updateIfExists) {
        this.tokens[token.id] = token;
        var group
        if (token.blockChainToken in this.#tokensMap) {
            group = this.#tokensMap[token.blockChainToken]
        } else {
            group = new Set();
        }

        group.add(token.id);
        this.#tokensMap[token.blockChainToken] = group;
    }

    getTokenFromId(id) {
        return this.tokens[id];
    }

    getTokensFromBlockChainToken(token) {
        var group = this.#tokensMap[token];
        if (group == null) {
            return [];
        }

        return group.map(x => this.getTokenFromId(x)); 
    }

    updateWithValues(values) {
        this.id = values.wallet_id;
        this.blockChainToken = values.block_token;
        this.insertedDate = values.inserted_date;
        this.source = values.source;
    }
}

export class WalletRepository {
    userId
    wallets
    tokens
    isGuest

    dispatch

    batchResolutionContext

    walletTypeMap

    constructor(user, dispatch) {
        this.userId = user;
        this.wallets = { };
        this.tokens = { };
        this.isGuest = user == GUEST_USER;
        this.dispatch = dispatch;
        this.walletTypeMap = { };
    }

    placeInRepository(wallet, updateIfExits) {
        var theWallet = this.wallets[wallet.id];
        if (theWallet != null && !updateIfExits) {
            return;
        }
        this.wallets[wallet.id] = wallet;
    }

    async loadRemoteWallets(wantsTokens) { // Load the remote wallets for the user, with an option to load the wallets
        if (this.isGuest) return;

        const walletData = await API.graphql({
            query: walletQuery,
            variables: { owner_id: this.userId, limit: 1000 }
        })
        
        if (walletData) {
            walletData.data.userWallets.forEach(wallet => {
                var model = new WalletModel(String(wallet.wallet_id));
                model.updateWithValues(wallet);

                this.dispatch(this.appendWallet(model));
            });
        }
    }

    // State Interface

    appendWallet = (wallet) => (dispatch, getState) => {
        if (this.isGuest) return;

        try {
            var theWallet = getState().walletReducer.repository.wallets[wallet.id];
            if (theWallet != null) {
                return;
            }
            dispatch({
                type: APPEND_WALLET,
                payload: { wallet }
            });
        } catch (error) {
            console.log("Error adding wallet: ", error);
        }
    }

    insertNewWallet = (newWallet) => async (dispatch, getState) => {
        if (this.isGuest) return;

        try {
            const result = await API.graphql({
                query: walletMutation,
                variables: { wallet: newWallet }
            })

            const wallet = result.data.createWallet;
            var model = new WalletModel(wallet.wallet_id);
            model.updateWithValues(wallet);

            dispatch({
                type: APPEND_WALLET,
                payload: { wallet: model }
            });
        } catch (error) {
            console.log("Error inserting new wallet: ", error);
        }
    }

    updateWallet = (updateValues) => async (dispatch, getState) => {
        if (this.isGuest) return;

        try {
            const result = await API.graphql({
                query: walletUpdate,
                variables: { updateValues }
            })

            const wallet = result.data.updateWallet;
            var model = new WalletModel(wallet.wallet_id);
            model.updateWithValues(wallet);

            dispatch({
                type: UPDATE_WALLET,
                payload: { wallet }
            });
        } catch (error) {
            console.log("Error updating wallet: ", error);
        }
    }

    removeWallet = (walletId) => async (dispatch, getState) => {
        if (this.isGuest) return;

        try {
            const result = await API.graphql({
                query: walletDeletion,
                variables: { wallet_id: walletId }
            })

            if (result) {
                dispatch({
                    type: DELETE_WALLET,
                    payload: { walletId }
                });
            }
        } catch (error) {
            console.log("Error removing wallet: ", error);
        }
    }

    resolveWallet = (resolution) => async (dispatch, getState) => {
        if (this.isGuest) return;

        if (resolution.resolved == true) {
            dispatch({
                type: RESOLVE_WALLET,
                payload: { resolution }
            })
        }
    }

    resolveAllWallets = (resolveTokens) => async (dispatch, getState) => {
        if (this.isGuest) return;

        var repoWallets = getState().walletReducer.repository.wallets;
        var resolutionRequests = Object.keys(repoWallets).map(function(key) {
            return {
                wallet_id: repoWallets[key].id,
                wallet_block_token: repoWallets[key].blockChainToken,
                wallet_category: "ETH",
                reply_with_tokens: true,
                include_token_categories: false
            };
        });

        API.graphql({
            query: walletInfoQuery,
            variables: { input: resolutionRequests, operation: "wallets" }
        }).then(resolutionData => {
            if (resolutionData.data.resolveWallets.items != null) {
                resolutionData.data.resolveWallets.items.forEach(resolution => {
                    dispatch({
                        type: RESOLVE_WALLET,
                        payload: { resolution }
                    });
                });

                if (resolutionData.data.resolveWallets.items.length > 0 && resolveTokens) {
                    getState().walletReducer.repository.beginBatchResolution(15);
                }
            }
        })
        .catch(err => console.log('Wallet resolution error: ', err));
    }

    resolveTokens = (tokens, batchFlag) => async (dispatch, getState) => {
        const requestMap = tokens.map((token) => {
            return {
                token_id: token.id,
                block_token: token.blockChainToken,
                contract_address: token.contractAddress,
                token_uri: token.uri
            }
        });

        API.graphql({
            query: tokenResolutionQuery,
            variables: { tokens: requestMap },
            authMode: this.isGuest ? GRAPHQL_AUTH_MODE.AWS_IAM : GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
        }).then(resolutionData => {
            if (resolutionData.data.resolveTokens.items != null) {
                // const walletKeys = Object.keys(getState().walletReducer.repository.wallets);
                resolutionData.data.resolveTokens.items.forEach(resolution => {
                    dispatch({
                        type: RESOLVE_TOKEN,
                        payload: { resolution }
                    })
                    
                    dispatch({
                        type: DO_RESOLUTION,
                        payload: {
                            reference: {
                                type: "WALLET_TOKEN",
                                id: resolution.token_id
                            },
                            map: getState().walletReducer.repository.tokens
                        }
                    })
                });

                if (batchFlag) {
                    this.dispatch({
                        type: UPDATE_BATCH_CONTEXT,
                        payload: {
                            context: {
                                ...getState().walletReducer.repository.batchResolutionContext,
                                isLoading: false
                            }
                        }
                    });

                    dispatch(getState().walletReducer.repository.nextResolution());
                }
            }
        })
        .catch(err => console.log('Token resolution error: ', err));
    }

    beginBatchResolution = async (batchSize) => {
        const context = {
            walletKeys: null,
            tokensKeys: null,
            batchSize: batchSize,
            isLoading: false,
            walletIndex: 0,
            walletTokenIndex: 0
        };

        this.dispatch({
            type: UPDATE_BATCH_CONTEXT,
            payload: { context: context }
        });

        console.log('Starting batch token resolution');
        this.dispatch(this.nextResolution());
    }

    nextResolution = () => async (dispatch, getState) => {
        var repository = getState().walletReducer.repository;
        var context = repository.batchResolutionContext;

        if (context == null) {
            return;
        }

        if (context.isLoading) {
            return;
        }

        context.isLoading = true;

        var nextTokens = [];

        const start = {
            wallet: context.walletIndex,
            token: context.walletTokenIndex
        };

        while (nextTokens.length < context.batchSize) {
            if (context.walletKeys.length <= context.walletIndex)
                break;

            var wallet = repository.wallets[context.walletKeys[context.walletIndex]];

            if (context.tokensKeys == null)
                context.tokensKeys = Object.keys(wallet.tokens);

            if (context.tokensKeys.length <= context.walletTokenIndex) {
                context.tokensKeys = null;
                context.walletTokenIndex = 0;
                context.walletIndex++;
                continue;
            }

            nextTokens.push(
                wallet.tokens[context.tokensKeys[context.walletTokenIndex]]
            );

            context.walletTokenIndex++;
        }

        if (nextTokens.length > 0) {
            console.log(
                "Batch resolution from: ",
                 "(",
                 start.wallet,
                 ",",
                 start.token,
                 ") to (",
                 context.walletIndex,
                 ",",
                 context.walletTokenIndex,
                 ")");
        }

        this.dispatch({
            type: UPDATE_BATCH_CONTEXT,
            payload: { context: context }
        });

        if (nextTokens.length > 0)
            dispatch(repository.resolveTokens(nextTokens, true));
    }
}

export const signInUser = (user) => (dispatch, getState) => {
    if (getState().userReducer.userId == user) {
        return
    }

    try {
        dispatch({
            type: SIGN_IN_USER,
            payload: { userId: user, dispatch: dispatch }
        });
    } catch (error) {
        console.log("Error signing in user: ", error);
    }
}

export const signOutUser = () => (dispatch, getState) => {
    try {
        dispatch({
            type: SIGN_OUT_USER,
            payload: {  }
        });
    } catch (error) {
        console.log("Error signing out user: ", error);
    }
}
